import React from "react";
import { IoMdClose } from "react-icons/io";
import { TermsAndPolicyLayoutWrapper } from "../../components/lib/TermsAndPolicyPagesLayout/termsAndPolicyPagesLayout.styles";
import LandingLayout from "../../components/Layout/landingLayout";

const TermAndConditions = () => {
  return (
    <LandingLayout>
      <TermsAndPolicyLayoutWrapper>
        <h1 className="terms__header">TERMS AND CONDITIONS</h1>
        <p className="terms__subheader">
          Welcome to our Terms and Conditions of Use. These terms affect your
          legal rights, endeavour to read them and treat with utmost importance.
        </p>

        <h3 className="terms__body__title">1. Introduction</h3>
        <p className="terms__body__text">
          Poprev (“Poprev”, “we”, “us”, “our”) is a service provider that
          provides our members (“you” “yourself” “Users”) with access to
          investable music projects, artist and product merchandise, content
          including but not limited to access to motion pictures and other
          audio-visual entertainment (“content”) delivered over the Internet, to
          Internet enabled devices. Poprev is owned and operated by Groove
          Platforms Limited (the Company”); a company duly registered in Nigeria
          (RC 1387955).
          <br />
          <br />
          These Terms of Use, including our Privacy Policy (the “Terms”), all
          together make up an Agreement that governs your relationship with
          Poprev and your use of our service. As used in these Terms of Use,
          “Poprev service” “our service”, or “service” means the subscription
          service provided by the Company for discovering and streaming the
          content, including all features and functionality, the Site, content,
          application, user interface and software associated with our service.
          <br />
          <br />
          By using the Poprev service, websites, platform, software applications
          and/or other affiliated services provided by us, our vendors or third
          parties with whom Poprev (as herein defined) contracts to provide the
          services that are inherently related to the music and investment
          service Poprev or that are compatible for similarly situated digital
          music services (together, the “Poprev Service” or “Service”),
          including by participating in music projects, purchasing or receiving
          content, rights or licenses, you are entering into a binding contract
          with our Company in your country of residence (your “Local Country”).
          Your agreement with us includes these Terms and Conditions of Use
          (“Terms”) and our Privacy Policy, all jointly referred to as the
          “Agreement”. If you don’t agree with these Agreements, then please
          don’t use the Service or affiliated services.
        </p>

        <h3 className="terms__body__title">2. Acceptance Of Terms Of Use</h3>
        <p className="terms__body__text">
          By accessing, visiting, using or browsing the Poprev Service, via this
          Site or any other device or application or other technology, you
          accept and agree to be bound by the Terms. Furthermore, you agree that
          you shall be subject to any additional terms of use, guidelines or
          rules that apply when you use certain products or Services within or
          connected to the Poprev Service, which may be posted and modified from
          time to time. All such guidelines are hereby incorporated by reference
          into the Terms and Agreement. ANY ACCESS, USE or PARTICIPATION IN THE
          POPREV SERVICE WILL CONSTITUTE TOTAL ACCEPTANCE OF THE TERMS. IF YOU
          DO NOT AGREE TO THESE TERMS OF USE, PLEASE DO NOT USE THE POPREV
          SERVICE, OUR SITE, APPLICATION OR ACCESS OUR SERVICE.
        </p>

        <h3 className="terms__body__title">3. Qualification for Use</h3>
        <p className="terms__body__text">
          You must be at least 18 years of age or have attained the age of
          majority in your province, territory or country, to become a member of
          the Poprev Service. Individuals under the age of 18, or applicable age
          of majority but above the age of 13, may utilize the service only with
          the involvement of a parent/legal guardian or under such person’s
          account and subject to these Terms. You must have the contractual
          capacity to enter into a binding contract with us and are not barred
          from doing so under any applicable laws.
          <br />
          <br />
          You warrant that any registration information that you submit to
          Poprev is true, accurate, up-to-date and complete, and you agree to
          keep it that way at all times. You also warrant that you shall
          maintain the security of your password and identification and shall be
          fully responsible for all use of your account and for any action that
          take place using your account. You agree that Poprev will not be held
          liable for any loss or damage that may arise in this respect.
          <br />
          <br />
          You warrant that you are the owner of the bank card and/account used
          for subscription to this Service and that you fully indemnify Poprev,
          to the fullest extent of the law, from any issues that may arise from
          the use of any Payment Method, bank card, bank account, etc.
          <br />
          <br />
          The use of the Services requires connection to a high-speed internet
          for mobile preferably through a 3rd or 4th Generation (3G or 4G)
          technology norm and you agree that any cost for accessing the internet
          shall be paid by you. This also applies when using the Service via
          mobile phone, tablet, or other personal device capable of internet
          connection. If you use the Services via mobile telephone you are
          advised to familiarize yourself with applicable data transfer costs
          for your mobile carrier plan. Poprev does not have any responsibility
          or liability for data transfer costs you may incur when using the
          Services. We do not guarantee that the Service will work on all
          devices. You agree that Poprev accepts no responsibility for any lack
          of functionality that is due to your equipment (including your device,
          internet connection, operating system or settings and software).
          <br />
          <br />
          You agree that you shall not use the Services in any way that is
          fraudulent or illegal or is expressly prohibited by these Terms.
          Poprev reserves the right to suspend or remove your account or
          prohibit your use of the Services, without responsibility or liability
          to you, if activities occur on your account that we believe breach the
          Agreement.
        </p>

        <h3 className="terms__body__title">4. Changes to the Agreement</h3>
        <p className="terms__body__text">
          The Company may in its sole discretion, from time to time, change the
          Terms at any time without notice. We will display the changes on this
          page of the Site or through a notification in the application and such
          changes shall be effective immediately. By continuing to use the
          Service after those changes are made, you acknowledge your acceptance
          of the changes. Please make sure you read Terms and changes thereto
          carefully. If you do not wish to continue using the service under the
          Terms or newer Terms, you may terminate the Agreement by ceasing to
          use the Service.
        </p>

        <h3 className="terms__body__title">5. Consent</h3>
        <p className="terms__body__text">
          By using the Poprev Service, you consent to receiving electronic
          communication from Poprev and the Company relating to your account. We
          will communicate with you by e-mail, or by posting notices on the Site
          or application or through other Poprev methods. For contractual
          purposes, you consent to receive communications from us electronically
          and you agree that all agreements, notices, disclosures and other
          communications that we provide to you electronically satisfy any legal
          requirement that such communication be in writing. You also consent to
          receiving other communication from us, including, but not limited to
          newsletters, special offers, questionnaires, customer surveys or other
          announcements via email or other methods. You may opt out of receiving
          non-transactional communications, including marketing communications
          from us by following the directions in our e-mail to “unsubscribe”
          from our mailing list, or by sending an e-mail request to
          hello@wearepoprev.com. Please be aware that if you choose not to
          receive such communication, certain offers attached to services you
          have chosen may be affected. Notwithstanding the above, you consent to
          receiving communications from us in connection to transactional
          communications, including but not limited to servicing your account,
          invoices and customer services, etc. Please review our privacy policy
          here{" "}
          <a href="https://poprev.dev/privacy" target="_blank" rel="noreferrer">
            "https://poprev.dev/privacy"
          </a>{" "}
          for other options with regards to opting out of cookies and
          interest-based advertising from third party advertising agencies.
        </p>

        <h3 className="terms__body__title">6. Poprev Services</h3>
        <p className="terms__body__text">
          Poprev Services - In order to access our service, you are required to
          create an account with Poprev by formally registering with Poprev, via
          the application, or a third party. Here’s some information about all
          the ways you can enjoy Poprev.
        </p>

        <h3 className="terms__body__title">6.1 Subscriptions</h3>
        <p className="terms__body__text">
          Poprev account holders may access the Poprev Service by any of our
          several portals (website, applications, etc).
          <br />
          <br />
          The music catalogue and music projects available , as part of the
          Poprev Service, is linked to the contracts in effect with the right
          holders and may therefore change. Poprev cannot guarantee the
          availability of any determined music project. Moreover, Poprev cannot
          guarantee that any determined music project will be profitable or that
          any msic project will be indefinitely available nor can Poprev be, in
          any manner; held liable for the removal of parts of the catalogue
          content or music project offered. We reserve the right in our sole and
          absolute discretion to make changes from time to time and without
          notice in how we offer and operate our Service.
        </p>

        <h3 className="terms__body__title">7. Intellectual Property</h3>
        <p className="terms__body__text">
          You agree that the Poprev Service and all elements of it including the
          logo, domain names, projects, tracks, videos, structure, app and other
          associated elements such as the photos, images, texts, biography of
          authors, artists and/or any legal beneficiary on the tracks or videos,
          including the visuals on the packaging are the exclusive property of
          Poprev and/or her licensors (including but not limited to the record
          producers, video producers, record companies, authors societies, etc.)
          <br />
          <br />
          The above elements are protected by relevant Intellectual property
          laws therefore you can only use these services and/or elements for the
          purpose of using the Poprev Service and in compliance with the Terms
          and Conditions
          <br />
          <br />
          You agree not to take part in the production, duplication,
          reproductions and/or imitation of any total or partial representation
          of any of the above elements or of the Poprev Service by any means
          whatsoever, without the express consent of Poprev. You agree that such
          an action is completely forbidden and would be considered an
          infringement of the law.
          <br />
          <br />
          You agree that any artificial use of an Poprev account (such use for
          instance aiming at artificially or abnormally increase the plays of
          certain specific music tracks), via automated processes such as robots
          and scripts or via any other means, is strictly prohibited.
          <br />
          <br />
          Please take note that the Service content made available within the
          Service are numerical files protected by national and international
          copyright and affiliated rights. Therefore, you agree that you may
          only listen and watch content on the Poprev Service within a private
          or personal setting. You also agree that any use for a non- personal
          purpose will expose the you to civil and/or criminal proceedings. You
          further agree that any other use of Service content is strictly
          forbidden and more particularly any download or attempt to download,
          any transfer or attempt to transfer permanently or temporarily on the
          hard drive of a computer or any other device (including music
          players), any burn or attempt to burn a CD or any other support are
          expressly forbidden. You also agree that any resale, exchange or
          renting of these files is strictly prohibited.
          <br />
          <br />
          As a user, you agree that you recognize that the Service content made
          available to you within the Poprev Service are protected by technical
          protection/security measures set up by Poprev in order to prevent or
          limit, depending on the technology offered, the unauthorized use of
          the Service content.
          <br />
          <br />
          You agree not to bypass, by any means, any and/or all technical
          protection measures, for any purpose, including downloading files and
          enabling their storing within the storage unit of your reception
          terminal whatever it is (computer, mobile phone, music player and
          other portable music disc player, etc.).
        </p>

        <h3 className="terms__body__title">
          8. License, Agreements and Restrictions
        </h3>
        <p className="terms__body__text">
          You agree to use the Poprev Service, including all features and
          functionalities associated therewith, in accordance with all
          applicable laws, rules and regulations, including public performance
          limitations or other restrictions on use of the service or content
          therein.
          <br />
          <br />
          The Service contains content, such as music projects, links to sound
          recordings, audiovisual works, other video or audio works, clips,
          images, graphics, text, software, works of authorship, files,
          documents, applications, artwork, trademarks, trade names, metadata,
          album titles, sound recording titles, artist names, intellectual
          property, or materials relating thereto or any other materials, and
          their selection, coordination and arrangement (collectively, the
          “Service Content” or “Content”). The Service Content is the property
          of Poprev and/or third parties and is protected by copyright under
          both Nigerian and foreign laws. The Service Content is made available
          to you only for your personal use as expressly permitted under these
          Terms.
          <br />
          <br />
          You agree that you are prohibited from using the Service and the
          Service Content as follows:
          <br />
          <br />
          You are prohibited from reproducing, modifying, copying, uploading,
          exporting, transferring, selling, forwarding, distributing or
          transmitting the Service Contents on the Site or in the application
          other than in accordance with the license granted and further agree
          not to reproduce any information of the Site or in the application on
          any other website, application, software, etc. without the prior
          written consent of the Service
          <br />
          <br />
          <b>Content Rights Holders:</b>
          <br />
          You are prohibited from either directly or through the use of any
          device, software, internet site, web-based service, or other means
          removing, altering, bypassing, avoiding, interfering with, or
          circumventing any copyright, trademark, or other proprietary notices
          marked on the content or any digital rights management mechanism,
          device, or other content protection or access control measure
          associated with the content including geo-filtering mechanisms.
          <br />
          <br />
          You are prohibited from either directly or through the use of any
          device, software, internet site, web-based service, or other download,
          streaming (except as explicitly authorized in these Terms), copying,
          capturing, reproducing, duplicating, archiving, distributing,
          uploading, publishing, modifying, translating, broadcasting,
          performing, displaying, selling, transmitting or retransmitting the
          Service Content unless expressly permitted by the Company in writing.
          <br />
          <br />
          You are prohibited from incorporating the Service content into, or
          streaming or retransmitting the content via, any hardware or software
          application or make it available via frames or in-line links unless
          expressly permitted by the Company in writing. Furthermore, You must
          create, recreate, distribute or advertise an index of any significant
          portion of the Service Content unless authorized by the Company. You
          are prohibited from circumventing, removing, altering, deactivating,
          degrading or thwarting any of the Service content protections in the
          Poprev service; using any robot, spider, scraper or other automated
          means to access the Poprev service; decompiling, reverse engineering
          or disassembling any software or other products or processes
          accessible through the Poprev service; inserting any code or product
          or manipulating the content of the Poprev service in any way; or,
          using any data mining, data gathering or extraction method.
          <br />
          <br />
          You are prohibited from uploading, posting, e-mailing or otherwise
          sending or transmitting any material designed to interrupt, destroy or
          limit the functionality of any computer software or hardware or
          telecommunications equipment associated with the Poprev service,
          including any software viruses or any other computer code, files or
          programs.
          <br />
          <br />
          You are prohibited from building a business utilizing the content,
          whether or not for profit. The content covered by these restrictions
          includes without limitation, any text, graphics, layout, interface,
          logos, photographs, audio and video materials, and stills. In
          addition, You are strictly prohibited from creating derivative works
          or materials that otherwise are derived from or based on in any way
          the content, including beats, soundtracks, montages, mash-ups and
          similar videos, wallpaper, desktop themes, greeting cards, and
          merchandise, unless it is expressly permitted by the us in writing.
          This prohibition applies even if you intend to give away the
          derivative materials free of charge. You are prohibited from arranging
          for any other website to be connected to the Site or application, in
          any manner, including by way of hyperlink or otherwise without prior
          written consent obtained from us.
          <br />
          <br />
          You are prohibited from modifying, enhancing, removing, interfering
          with, or otherwise altering in any way any portion of the poprev
          solution, logic, interface, its underlying technology, any digital
          rights management mechanism, device, or other content protection or
          access control measure incorporated into poprev product and service.
          This restriction includes, without limitation, disabling, reverse
          engineering, modifying, interfering with or otherwise circumventing
          the poprev service in any manner.
          <br />
          <br />
          You are prohibited from using the Services for commercial or public
          purposes, the Service is strictly for private use.
          <br />
          <br />
          You are prohibited from gaining access to such Service Content via
          other mechanisms than those available via the Services. The Services
          may include components that make use of temporary storage of certain
          Service Content on the various platforms (also called “offline mode”).
          You have access to this Service Content for as long as you have a
          valid Subscription. Such Service Content shall not be copied and/or
          made available outside the Services.
          <br />
          <br />
          The Poprev Service and the content provided through it are the
          property of Groove Platforms Limited or it’s licensors, and we grant
          you a limited, non-exclusive, revocable licence to make personal,
          non-commercial use of the Poprev Service and to receive the media
          content made available through the Poprev Service in your Local
          Country, based on the Subscription or Trial you have selected (the
          “Licence”). This Licence shall remain in effect for a period of 20
          years unless terminated by you or Poprev.
          <br />
          <br />
          The Poprev software applications are licensed, not sold, to you, and
          Poprev retains ownership of all copies of the Poprev software
          applications even after installation on your Devices. We may assign
          these Agreement or any part of them without restrictions. You may not
          assign these Agreement or any part of them, nor transfer or
          sub-licence your rights under this Licence, to any third party.
          <br />
          <br />
          All Poprev trademarks, service marks, trade names, logos, domain
          names, and any other features of the Poprev brand are the sole
          property of Groove Platforms Limited. This Licence does not grant you
          any rights to use the Poprev trademarks, service marks, trade names,
          logos, domain names, or any other features of the Poprev brand,
          whether for commercial or non-commercial use.
          <br />
          <br />
          You agree to abide by our terms and not to use the Poprev Service
          (including but not limited to its content) in any manner not expressly
          permitted by the Terms and Agreement. Third party software libraries
          included in the Poprev Service are licensed to you either under these
          Terms, or under the relevant third-party software library’s licence
          terms as published in the help or any other section of our website/
          mobile app.
          <br />
          <br />
          Nothing in this Agreement or on the Services should be construed as
          granting, by implication, estoppel, or otherwise, any license or right
          to use the Trademark, without Poprev’s prior written permission
          specific for each such use. Use of the Trademark as part of a link to
          or from any site is prohibited unless establishment of such a link is
          approved in advance by Poprev in writing. All goodwill generated from
          the use of the Trademark inures to Poprev’s benefit.
          <br />
          <br />
          Unauthorized use of the Services may also constitute a violation of
          applicable laws or regulations. If Poprev, in its sole discretion,
          thinks you have breached the Terms, or has reasonable grounds to
          believe that you are likely to breach these Terms, we may take any
          action we think is necessary to protect the Services and the Service
          Content.We may:
          <br />
          <br /> Restrict, suspend, or terminate your right to use the Services,
          remove your User Content (as defined below) from the Services.
          <br />
          <br />
          Investigate and take legal proceedings against you; or disclose any
          information to law enforcement authorities as we think is necessary or
          as required by law.
          <br />
          <br />
          These actions are not limited and we may take any other action we
          reasonably deem appropriate.
        </p>

        <h3 className="terms__body__title">9. Third Party Applications</h3>
        <p className="terms__body__text">
          Third Party Applications- The Poprev Service provides a platform for
          third party applications, websites and services to make available
          products and services to you (“Third Party Applications”), and your
          use of these Third-Party Applications is subject to their terms of
          use. You understand and agree that Poprev is not responsible or liable
          for the behaviour, features, or content of any Third-Party Application
          and does not make any representations regarding the behaviour,
          features, content or accuracy of materials on any Third-Party
          Application. You should take precautions when downloading files from
          all third party applications and websites to protect your
          computer/device from viruses and other destructive programs. If you
          decide to access linked Third Party Applications, you do so at your
          own risk. Please contact the administrator or webmaster for those
          Third-Party Application if you have any concerns regarding such
          Third-Party Application or any content located on such Third Party
          Application.
        </p>

        <h3 className="terms__body__title">10. User Generated Content</h3>
        <p className="terms__body__text">
          Poprev users may post, upload and/or contribute (“post”) content to
          the Service, including pictures, text and playlist compilations (“User
          Content”). You represent that you have the right to post any User
          Content which you post to the Service, and that such User Content, or
          its use by us as contemplated in this Agreement, does not violate
          these Agreements, applicable law, and/or the intellectual property
          rights of others.
          <br />
          <br />
          By submitting any User Content on or through the Services, you hereby
          grant to Poprev a license (with the unrestricted right to sub-license)
          to store, host, display, stream, transmit conditional downloads,
          exploit, promote, market, sell and transmit permanent downloads, use,
          modify, delete from, add to, publicly perform, publicly display,
          reproduce, create and use derivative works of, distribute and
          otherwise disseminate the User Content on or through the Services via
          any and all devices known or hereinafter devised. For the avoidance of
          doubt, to the extent you use the Edit Functionality to create a Remix,
          you acknowledge and agree that you own no right, title or interest
          therein and that any such Remix shall not be considered your User
          Content hereunder.
          <br />
          <br />
          You grant us a non-exclusive, transferable, sub-licensable, fully
          paid, royalty-free, worldwide license to use any User Content that you
          post on or in connection with Poprev. This license lasts until you
          terminate your Poprev account, except in the case of User Content that
          you have published, made public and/or shared with others. Aside from
          the rights specifically granted herein, you retain ownership of all
          rights, including intellectual property rights, in the User Content
          that you post to the Poprev Service, except that, where applicable
          under Local laws, you agree to waive your right to be identified as
          the author of any User Content on the Poprev Service and your right to
          object to derogatory treatment of such User Content. The licenses
          granted in this section are granted separately with respect to each
          item of the User Content that you submit to the Services.
          <br />
          <br />
          <b>
            {" "}
            By uploading the User Content, You represent, promise and warrant to
            us that the User Content submitted by you on or through the
            Services:
          </b>
          <br />
          <br />
          is owned by you or you otherwise have the right to grant the license
          set forth herein;
          <br />
          <br />
          does not infringe the rights of any third party, including any
          intellectual property rights, rights of confidential information or
          rights in privacy;
          <br />
          <br />
          does not imply any affiliation, endorsement, approval or cooperation
          by Poprev or any artist, band, label, entity or individual without
          express written consent from such individual or entity; and
          <br />
          <br />
          shall comply with all applicable laws and these Terms.
          <br />
          <br />
          You agree to pay for all royalties, fees, and any other monies owing
          any person or entity by reason of any User Content submitted by you on
          or through the Services.
          <br />
          <br />
          You also grant to us, the right to perform technical functions
          necessary to offer the service on the Services, including but not
          limited to transcoding and/or reformatting User Content to allow its
          use throughout the Services.
          <br />
          <br />
          You agree that we may reject, refuse to post or delete any User
          Content for any or no reason, including User Content that in our sole
          judgment breaches this Agreement or which may be offensive, illegal or
          breach the rights of any third party, or harm or threaten the safety
          of any third party. Poprev is under no obligation to you or any other
          party to monitor, review, moderate or edit User Content, but reserves
          the right to remove or disable access to any User Content for any or
          no reason, including but not limited to, User Content that, in
          Poprev’s sole discretion, violates these Agreements. Poprev may take
          these actions without prior notification to you. We may, however, at
          our sole discretion moderate and review User Content to ensure that it
          complies with these Terms. Nevertheless, if we choose to monitor the
          Services, we assume no responsibility or liability to you for any User
          Content available on the Services.
          <br />
          <br />
          You agree that you are solely responsible for the User Content that
          you submit to the Services. You must not upload, store, distribute,
          send, transmit, display, perform, make available or otherwise
          communicate to the public any content to which you do not hold the
          necessary rights. Please note that any unauthorized use of copyright
          protected material within the User Content (including by way of
          reproduction, distribution, modification, adaptation, public display,
          public performance, preparation of derivative works, making available
          or otherwise communicating to the public via the Services) may
          constitute an infringement of third party rights and is strictly
          prohibited. Any such infringements may result in civil litigation or
          criminal prosecution by or on behalf of the relevant rights holder.
          You warrant that you will indemnify and defend Poprev in the event
          that any user content uploaded by you is an infringement of a third
          party’s right.
          <br />
          <br />
          <br />
          Poprev is not responsible for User Content nor does it endorse any
          opinion contained in User Content. If you believe that any User
          Content infringes your intellectual property rights, does not comply
          with the User Guidelines (Section 11 below) or that your rights under
          applicable law have been otherwise infringed by any User Content,
          please contact us at [email protected].
        </p>

        <h3 className="terms__body__title">11. User Guidelines</h3>
        <p className="terms__body__text">
          We have established a few ground rules for you to follow when using
          the Service, to make sure Poprev stays enjoyable for everyone. Please
          ensure you follow these rules and encourage other users to do the
          same. Poprev respects intellectual property rights and expects you to
          do the same.
          <br />
          <br />
          <br />
          <b>
            {" "}
            Poprev respects intellectual property rights and expects you to do
            the same. This means, that the following is not strictly prohibited:
          </b>
          <br />
          <br />
          Copying, reproducing, “ripping”, recording, or making available to the
          public any part of the Poprev Services or content delivered to you via
          the Poprev Services, or otherwise any making use of the Poprev Service
          which is not expressly permitted under these Terms;
          <br />
          <br />
          using the Poprev service to import or copy any local files you do not
          have the legal right to import or copy in this way;
          <br />
          <br />
          reverse-engineering, decompiling, disassembling, modification or
          creating derivative works based on the Poprev Services or any part
          thereof;
          <br />
          <br />
          protect content accessible through the Service;
          <br />
          <br />
          renting or leasing of any part of the Services;
          <br />
          <br />
          circumventing of any territorial restrictions applied by Poprev;
          <br />
          <br />
          artificially increasing play count or otherwise manipulating the
          Services by using a script or other automated process;
          <br />
          <br />
          removing or altering any copyright, trademark or other intellectual
          property notices contained in or provided through the Poprev Service;
          <br />
          <br />
          providing your password to any other person or using any other
          person’s username and password.
          <br />
          <br />
          Please be respectful to Poprev and other users of the Service at all
          times. You are prohibited from engaging in any activity on the Service
          or upload User Content, including registering and/or using a username,
          which is or includes material that is offensive, abusive, defamatory,
          pornographic or obscene; is illegal, or intended to promote or commit
          an illegal act of any kind, including but not limited to, violations
          of intellectual property rights, privacy rights or proprietary rights
          of Poprev or a third party;
          <br />
          <br />
          includes personal data of third parties or is intended to solicit such
          personal data, includes malicious content such as malware, trojan
          horses or viruses, or otherwise interferes with any user’s access to
          the Service;
          <br />
          <br />
          is intended to or does harass or bully other users; impersonates or
          misrepresents your affiliation with another user, person or entity, or
          is otherwise fraudulent, false, deceptive, or misleading; uses
          automated means to artificially promote content;
          <br />
          <br />
          involves the transmission of unsolicited mass mailing (“spam”), junk
          mail, chain letter, or similar, including through the Poprev inbox;
          involves commercial or sales activities, such as advertising,
          contests, sweepstakes, or pyramid schemes;
          <br />
          <br />
          promotes commercial products or services; interferes with the Poprev
          Service, tampers with or attempts to probe, scan, or test for
          vulnerabilities in the Service or Poprev’s computer systems or
          network, or breaches any of Poprev’s security or authentication
          measures, or conflicts with the Agreement, as determined by Poprev.
          <br />
          <br />
          You agree that Poprev may also reclaim your username for any reason,
          including a breach of the above restrictions.
          <br />
          <br />
          <br />
          The Poprev Service includes social and interactive features, including
          the ability to post User Content, share content, and make certain
          information about you public, as further described in your account
          settings. We advise that you are thoughtful about what you make public
          on Poprev and remember at all times that shared or publicly available
          information may be used and re-shared by other users on Poprev or
          across the web, so please use Poprev carefully and manage your
          account/privacy settings regularly. You agree that Poprev takes no
          responsibility nor are we liable for your choices to make any actions
          or material public on the Service.
          <br />
          <br />
          Your password protects your user account, and you agree that you are
          solely responsible for keeping your password confidential and secure.
          You understand that you are responsible for all use of your username
          and password on the Service. If your username or password is lost or
          stolen, or if you believe there has been unauthorized access to your
          account by third parties, please notify us immediately by sending an
          email to [email protected] and change your password as soon as
          possible.
        </p>

        <h3 className="terms__body__title">12. Prohibited Content/Activity</h3>
        <p className="terms__body__text">
          We are committed to providing a safe space for everyone that uses the
          Services therefore we expect all Users to conduct themselves
          appropriately while using the Services. The safety of our users is
          important to us, so we have identified some activities we believe can
          interfere with the safety of all users on the platform.
          <br />
          <br />
          <br />
          <b>
            {" "}
            You agree that you will not upload or share any User content which
            in Poprev’s reasonable opinion:
          </b>
          <br />
          <br />
          Harasses or advocates harassment of another person;
          <br />
          <br />
          Exploits people in a sexual or violent manner;
          <br />
          <br />
          Constitutes or promotes information that you know is false or
          misleading or promotes illegal activities or conduct that is abusive,
          threatening, obscene, defamatory or libellous;
          <br />
          <br />
          Constitutes or promotes information that promotes discrimination
          against any class of persons;
          <br />
          <br />
          Contains nudity, excessive violence, or offensive subject matter or
          contains a link to an adult website; Solicits personal information;
          <br />
          <br />
          Constitutes information that poses or creates a privacy or security
          risk to any person;
          <br />
          <br />
          Constitutes or promotes an illegal or unauthorized copy of another
          person’s copyrighted work, such as providing pirated computer programs
          or links to them, providing information to circumvent
          manufacturer-installed copy-protect devices, or providing pirated
          music or links to pirated music files;
          <br />
          <br />
          Involves the transmission of “junk mail,” “chain letters,” or
          unsolicited mass mailing, instant messaging, “spimming,” or
          “spamming”; Contains restricted or password-only access pages or
          hidden pages or images (those not linked to or from another accessible
          page); Furthers or promotes any criminal activity or enterprise or
          provides instructional information about illegal activities including,
          but not limited to making or buying illegal weapons, violating
          someone’s privacy, or providing or creating computer viruses; Solicits
          passwords or personal identifying information for commercial or
          unlawful purposes from other users; Breaches the privacy rights,
          publicity rights, copyrights, trademark rights, contract rights or any
          other rights of any person.{" "}
          <b>
            You further agree that you will not undertake in any of the
            following activities on the Services:
          </b>
          <br />
          <br />
          Criminal or tortious activity, including, child pornography, fraud,
          trafficking in obscene material, drug dealing, gambling, harassment,
          stalking, spamming, spimming, sending of viruses or other harmful
          files, copyright infringement, patent infringement, or theft of trade
          secrets;
          <br />
          <br />
          Discrimination such, obscenity, hateful language/behavior,
          anti-Semitic messages, xenophobia, racism, fanaticism, bigotry,
          harassment, hate crimes, bullying, encouraging violence,
          discrimination based on ethnicity or race and other activities of
          revolutionary nature or actions that go against public orders and good
          manners including hatred or physical harm of any kind against any
          group or individual; Advertising to, or solicitation of, any user,
          without the written consent of Poprev to buy or sell any products or
          services through the unauthorized or impermissible use of the
          Services, including the use of user tracking, commercial activities
          and/or sales without prior written consent from Poprev such as
          contests, sweepstakes, barter, advertising, or pyramid schemes; <br />
          <br />
          Circumventing or modifying, attempting to circumvent or modify, or
          encouraging or assisting any other person in circumventing or
          modifying any security technology or software that is part of the
          Services; Activity that involves the use of viruses, bots, worms, or
          any other computer code, files or programs that interrupt, destroy or
          limit the functionality of any computer software or hardware, or
          otherwise permit the unauthorized use of or access to a computer or a
          computer network;
          <br />
          <br />
          Covering or obscuring the banner advertisements on any page on the
          Services via HTML/CSS or any other means; Any automated use of the
          system, such as, but not limited to, using scripts to submit
          information or send comments or messages; Interfering with,
          disrupting, or creating an undue burden on the Services or the
          networks or services connected to the Services; <br />
          <br />
          Impersonating or attempting to impersonate another user, person or
          entity; Using the account, username, or password of another user at
          any time or disclosing your password to any third party or permitting
          any third party to access your account; <br />
          <br />
          Selling or otherwise transferring your account; <br />
          <br />
          Using any information obtained from the Services in order to harass,
          abuse, or harm another person or entity, or attempting to do the same;{" "}
          <br />
          <br />
          Accepting payment or anything of value from a third person in exchange
          for your performing any commercial activity through the unauthorized
          or impermissible use of the Services on behalf of that person; <br />
          <br /> or Using the Services in a manner inconsistent with any and all
          applicable laws and regulations. You hereby agree that any breach of
          the provisions of this Section will be considered a serious breach of
          the Agreement.
        </p>
        <h3 className="terms__body__title">13. Submissions</h3>
        <p className="terms__body__text">
          We have provided our contact information and email links on the
          Services because we would like to hear from you. However, we do not
          want you to, and you should not, send Poprev any documents or other
          materials that contain confidential or proprietary information. You
          hereby grant Poprev an unrestricted, irrevocable, royalty-free license
          to copy, distribute, and modify any such documents or other materials
          that you send Poprev, and to use it for any purpose any ideas, trade
          secrets, know-how or other confidential or proprietary information
          disclosed in those documents or other materials, and that all of the
          representations and promises you make pursuant to this Agreement with
          respect to User Content apply equally with respect to any other
          documents or materials that you send to Poprev that do not constitute
          User Content.
        </p>

        <h3 className="terms__body__title">14. Copyright Infringement</h3>
        <p className="terms__body__text">
          All rights reserved. No part of Poprev may be copied, reproduced,
          ripped, recorded, re-engineered, decompiled, disassembled, modified
          reproduced, distributed, or transmitted in any form or by any means
          either electronic or mechanical methods, without the prior written
          permission of Poprev. For permission requests, write to the Legal
          through{" "}
          <a
            href="mailto:hello@wearepoprev.com"
            target="_blank"
            rel="noreferrer"
          >
            hello@wearepoprev.com
          </a>
          <br />
          <br />
          <br />
          Poprev, at its sole discretion, may disable and/or terminate use of
          the Services by users who infringe the intellectual property rights of
          others. Our procedure for addressing allegations of copyright
          infringement may include but are not limited to:
          <br />
          <br />
          immediately removing the subject of such infringement claim (service
          and user content inclusive).
          <br />
          <br />
          We may attempt to contact the provider of the removed content to
          notify them of the removal and possibly request a counter position at
          our sole discretion.
          <br />
          <br />
          We will terminate the accounts of any user and/or block present and
          future access to the Services of Users that repeatedly infringe the
          copyrights of others.
          <br />
          <br />
          <br />
          <b>
            {" "}
            If at any time you believe that your work has been copied in a way
            that constitutes a copyright breech or infringement, or that your
            rights (intellectual property and/or privacy rights) have been
            breached, please provide us with a notice highlighting the following
            information:
          </b>
          <br />
          <br />
          An electronic or physical signature of the person authorized to act on
          behalf of the owner of the copyright or other intellectual property
          interest; A detailed description of the copyrighted work or other
          intellectual property that you claim has been infringed;
          <br />
          <br />
          A detailed description of where the material that you claim is
          infringing is located on the website or the app (please note that
          including URL(s) in the body of an email is the best way to help us
          locate content quickly);
          <br />
          <br />
          Your name, address, telephone number, and email address; A statement
          by you that you have a good faith belief that the disputed use is not
          authorized by the copyright owner, its agent, or the law;
          <br />
          <br />
          A statement by you, made under penalty of perjury, that the above
          information in your notice is accurate and that you are the copyright
          or other intellectual property right owner or authorized to act on the
          copyright or intellectual property right owner’s behalf; and Your
          signature. Send your notice to Poprev Copyright Department, 6a, Wole
          Ariyo Street, Lekki Phase 1, Lagos or by email to [email protected].
          Please note that you may be liable for damages (including costs and
          attorneys’ fees) if you materially misrepresent that any material is
          infringing your copyrights or other intellectual property or privacy
          rights.
          <br />
          <br />
          The provider of Removed Content may make a counter notification
          following which we may reinstate the removed content at our sole
          discretion if we have good reason to believe no breach of your
          intellectual property has taken place. The counter notification must
          be made in writing and include the following items: a detailed
          identification of the material the Poprev has removed; your name,
          address, telephone number, e-mail address, and
          <br />
          <br />
          a statement that you consent to the jurisdiction of the High Court of
          Lagos State and that you will accept service of process from the
          person who provided notification or an agent of such person;the
          statement: “I swear, under penalty of perjury, that I have a good
          faith belief that each search result or the message identified above
          was removed or disabled as a result of a mistake or misidentification
          of the material to be removed or disabled.”;
          <br />
          <br />
          and your signature.
          <br />
          <br />
          <br />
          The counter notification can be sent to Poprev Legal Department, 6a,
          Wole Ariyo Street, Lekki Phase 1, Lagos or by email to legal@groove.ng
          Please note that you may be liable for damages (including costs and
          attorneys’ fees) if you materially misrepresent that a product or
          activity is not infringing the copyrights of others.
          <br />
          <br />
          <br />
          If you are the owner of any intellectual property other than
          copyrighted materials (such as, for example, a trademark), or an agent
          thereof, and believe that any item of content or other materials on
          the Services infringes upon your rights in such intellectually
          property, you must submit a notification to us in the same manner as
          provided above in respect of notice of alleged copyright infringement,
          including providing the same information in writing to the designated
          address set forth above.
          <br />
          <br />
          <br />
          Disclaimer: please note that we are not your legal advisors, therefore
          the information we present here is not legal advice. This information
          is presented for informational purposes only, and as required by law .
        </p>

        <h3 className="terms__body__title">
          15. Technology Limitations And Modifications
        </h3>
        <p className="terms__body__text">
          We will make reasonable efforts to keep the Services operational.
          However, certain technical difficulties or maintenance, including but
          not limited to updates and repairs, may, from time to time, result in
          temporary interruptions. Poprev reserves the right, periodically and
          at any time, to modify or discontinue, temporarily or permanently,
          functions and features of the Poprev Service, with or without notice,
          all without liability to you for any interruption, modification, or
          discontinuation of the Poprev Service or any function or feature
          thereof. You understand and agree that Poprev has no obligation to
          maintain, support, upgrade, or update the Service, or to provide all
          or any specific content through the Service and hereby waive any right
          to compensation and/or action against Poprev in this respect.
        </p>

        <h3 className="terms__body__title">16. Export Control</h3>
        <p className="terms__body__text">
          You agree that you shall not – directly or indirectly – sell, export,
          re-export, transfer, divert, or otherwise dispose of any products,
          software, or technology (including products derived from or based on
          such technology) received from Poprev under these Agreements to any
          destination, entity, or person prohibited by the laws or regulations
          of the Federal Republic of Nigeria and your territory, without
          obtaining prior authorization from the competent government
          authorities as required by those laws and regulations. This export
          control clause shall survive termination or cancellation of these
          Agreements.
        </p>

        <h3 className="terms__body__title">
          17. Payments, Renewal And Cancellation
        </h3>
        <p className="terms__body__text">
          <br />
          <b>Payment:</b>
          <br />
          In connection with any and all Subscriptions that you select to
          purchase you must provide Poprev with a current, valid, accepted
          method of payment, which may change from time to time (“Payment
          Method”). All payments, Subscription purchases are subject to this
          Agreement and any additional terms and conditions, including any fees,
          imposed by your financial services provider and/or mobile carrier, as
          applicable. You are solely responsible for reviewing the terms of use,
          privacy policy or any other terms governing your use of the services
          provided by your financial services provider and/or mobile carrier.
          <br />
          <br />
          The Payment system(s) on Poprev are provided by a third-party payment
          service provider(s) with whom we have entered into agreement(s) with
          as such the guarantee, if any provided by Poprev is identical to that
          obtained by us from the payment service provider(s). The computerized
          records stored our computer/data systems and that of our payment
          service partners, within reasonable security conditions shall serve as
          proof of payment, communications, validation and orders that took
          place between you and Poprev unless you can provide conflicting
          written proof to the contrary.
          <br />
          <br />
          By providing a Payment Method in connection with any Subscription, you
          hereby authorize Poprev to debit the total cost of said Subscription
          and agree to be responsible for any foreign transaction charges
          associated with your Subscription purchase. Should charges for which
          you are responsible fail at the time payment is required, you may be
          responsible for costs associated with Poprev’s efforts to collect
          amounts due in accordance with applicable laws. You represent and
          promise to us that all information you provide in connection with the
          purchase of a Subscription is true, accurate and complete. The
          duration and cost of Subscriptions are set by Poprev in its sole
          discretion. When you purchase a Subscription, you will initially be
          charged at the rate applicable at the time of your initial agreement
          to subscribe. If the price of the Subscription increases later, we
          will provide you with notice before the end of the billing period in
          which the change is made. Any increase in VAT (Value Added Tax) or
          other applicable tax will be automatically and immediately effected on
          the subscription cost starting from the next applicable subscription
          period. If you do not accept the change to your Subscription, you will
          be entitled to terminate this Agreement at least 72 (Seventy- Two)
          hours before your current subscription expires, should you fail to do
          so, you will be taken to have agreed to the new subscription cost.
          <br />
          <br />
          <br />
          <b> Billing Cycle:</b>
          <br />
          As indicated during sign-up, the Subscription fee must be paid in a
          single upfront payment. Once you indicate your interest to total
          Subscription fee due and payable to Poprev for a subscription circle,
          your payment method will be automatically billed at that time.
          <br />
          <br />
          <br />
          <b>No Rights of Refund</b>
          <br />
          You agree that the supply of Poprev Service starts at the time of
          registration and participating in a music project therefore you
          consequently lose any right to withdraw from the investment or
          participation once payment has been made. As such, no request for
          revocation or refund will be accepted after such validation.
          <br />
          <br />
          <br />
          <b>Automatic Renewal:</b>
          <br />
          If you have a paid subscription for the Poprev merchandise service,
          your payment to Poprev will be automatically renewed at the end of the
          subscription period, unless you cancel your paid subscription through
          your subscription page before the end of the current subscription
          period. Renewal will be for the same duration of the original
          subscription term and will be billed at the non-discounted rate for
          the subscription, according to your original billing cycle method.
          <br />
          <br />
          By enrolling in a Subscription, you agree that your Subscription will
          be subject to the above described automatic renewal unless you cancel
          your subscription in accordance with the terms of this Agreement or
          except you subscribe using a non- recurring payment method which would
          require you to manually renew your subscription. You also agree that
          the amount billed may be different if your original Subscription was
          purchased using a promotional/trial offer and you authorize Poprev to
          charge your payment method for such varying amounts as indicated at
          the time of sign-up or the full subscription charge; whichever is
          applicable. You agree that you are responsible for other charges,
          including any foreign transaction charges, that may be imposed by
          credit card providers, payment processors or other third parties in
          connection with your use of the subscription. Your subscription will
          remain in effect and continue to renew automatically until it is
          cancelled.
          <br />
          <br />
          <br />
          <b>Cancellation:</b>
          <br />
          If you decide to cancel your subscription for merchandise, the
          cancellation will take effect the day after the last day of the
          current subscription period, if the request is made at least 72
          (Seventy-Two) hours prior to expiry of your current subscription
          period and you will be downgraded to the Free Service or your use of
          the service suspended till you renew your subscription based on
          Poprev’s sole discretion.
          <br />
          <br />
          Poprev may change the price for the paid subscriptions from time to
          time and will communicate any price changes to you. Price changes for
          paid subscriptions will take effect at the start of the next
          subscription circle following the date of the price change. By
          continuing to use the Poprev Service after the price change takes
          effect, you accept the new price.
          <br />
          <br />
          <br />
          <b> Refund Policy:</b>
          <br />
          We will not refund any fees paid to us, including any advance
          charge(s) or payment(s) for any subscription term for the Services,
          unless otherwise required by law.
          <br />
          <br />
          <br />
          <b>Charge Backs:</b>
          <br />
          If you dispute subscription charges with your credit card company or
          financial institution, and Poprev’s investigation determines the
          charges were valid, we may, on reasonable written notice to you,
          charge to your Poprev account the reasonable required cost of
          responding to your charge back request.
        </p>

        <h3 className="terms__body__title">18. Limitation</h3>
        <p className="terms__body__text">
          You agree that, to the extent permitted by applicable law, your sole
          and exclusive remedy for any problems or dissatisfaction with the
          Poprev Service, the Third-Party Applications or the Third-Party
          Application content is to uninstall any Poprev software and to stop
          using the Poprev Service, the Third-Party Applications or the
          Third-Party Application content.
          <br />
          <br />
          <br />
          TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL POPREV, ITS
          OFFICERS, SHAREHOLDERS, EMPLOYEES, AGENTS, DIRECTORS, SUBSIDIARIES,
          AFFILIATES, SUCCESSORS, ASSIGNS, SUPPLIERS OR LICENSORS BE LIABLE FOR{" "}
          <br />
          <br />
          (a) ANY DAMAGE WHATSOEVER (INCLUDING, WITHOUT LIMITATION) INDIRECT,
          SPECIAL, INCIDENTAL, PUNITIVE, EXEMPLARY OR CONSEQUENTIAL (INCLUDING
          LOSS OF USE, DATA, BUSINESS, OR PROFITS) DAMAGES, ARISING OUT OF THE
          USE OR INABILITY TO USE THE POPREV SERVICE, THIRD PARTY APPLICATIONS
          OR THIRD PARTY APPLICATION CONTENT, REGARDLESS OF LEGAL THEORY,
          WARRANTEE, CONTRACT, TORT, WITHOUT REGARD TO WHETHER POPREV HAS BEEN
          WARNED OF THE POSSIBILITY OF THOSE DAMAGES, AND EVEN IF A REMEDY FAILS
          OF ITS ESSENTIAL PURPOSE;
          <br />
          <br />
          <br />
          <br />
          THE AGGREGATE LIABILITY FOR ALL CLAIMS RELATING TO THE POPREV SERVICE,
          THIRD PARTY APPLICATIONS OR THIRD-PARTY APPLICATION CONTENT SHALL NOT
          SURPASS MORE THAN THE AMOUNTS PAID BY YOU TO POPREV DURING THE PRIOR
          THREE MONTHS IN QUESTION.
        </p>

        <h3 className="terms__body__title">19. Indemnification</h3>
        <p className="terms__body__text">
          You are solely responsible for your use of the Service and Service
          Contents therefore to the fullest extent permitted by applicable law,
          you agree to defend, indemnify, and hold Poprev harmless from and
          against all claims, actions or demands, including, without limitation,
          reasonable legal and accounting fees, arising from, relating to or
          resulting from:
          <br />
          <br />
          your breach or alleged breach of the Agreement;
          <br />
          <br />
          your access to, use or misuse of the Service Content or the Services;
          <br />
          <br />
          any breach of your representations and promises set forth herein, or
          your violation of any law or the rights of a third party.
          <br />
          <br />
          We shall provide you with notice of any such claim, suit, or
          proceeding and shall assist you, at your expense, in defending any
          such claim, suit or proceeding. We reserve the right to assume the
          exclusive defense and control of any matter that is subject to
          indemnification under this section. In such case, you agree to
          cooperate with any reasonable requests assisting our defense of such
          matter.
        </p>

        <h3 className="terms__body__title">20. Term And Termination</h3>
        <p className="terms__body__text">
          These Terms will continue to apply to you until terminated by either
          you or Poprev. Poprev may terminate the Terms/Agreement or suspend
          your access to the Service at any time; without any notice,
          responsibility or liability to you; including in the event of your
          actual or suspected unauthorized use of the Poprev Services or non-
          compliance with the Terms. If you or Poprev terminate the Terms, or if
          Poprev suspends your access to the Service, you agree that Poprev
          shall have no liability or responsibility to you, to the fullest
          extent permitted under applicable Laws and Poprev will not refund any
          amounts that you have already paid. Poprev may terminate this
          Agreement and close your account at any time, without notice to you,
          if we cease to provide the Services.
          <br />
          <br />
          To learn how to terminate your Poprev account, please contact us
          through the Customer Service contact form.
        </p>

        <h3 className="terms__body__title">21. Warranty</h3>
        <p className="terms__body__text">
          We shall endeavour to provide the best Service we can, but you
          understand and agree that THE POPREV SERVICE IS PROVIDED “AS IS” and
          “AS AVAILABLE” WITHOUT EXPRESS OR IMPLIED WARRANTY OR CONDITIONS OF
          ANY KIND. YOU AGREE THAT YOU USE THE POPREV SERVICE AT YOUR OWN RISK.
          POPREV DISCLAIMS ALL WARRANTIES AND/OR CONDITIONS INCLUDING
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-
          INFRINGEMENT. Poprev also fully reserves the right to change the
          functionality and content offered under the Services on an on-going
          basis. Poprev makes no warrantees or representations about the
          Services, including but not limited to their accuracy, reliability,
          completeness, efficacy or timeliness; neither shall Poprev be subject
          to liability for truth, accuracy, or completeness of any information
          conveyed to the user or for errors, mistakes or omissions or for any
          delays or interruptions of the data or information stream from
          whatever cause. You agree that your use of the Services or Service
          Content is at your own risk. It is your responsibility to evaluate the
          accuracy, completeness or usefulness of any opinion, advise or other
          Service Content available through the Services.
          <br />
          <br />
          <br />
          In addition, Poprev does not warrant, endorse, guarantee or assume
          responsibility for any Third-Party Applications, Third Party
          Application content, User Content, or any other product or service
          advertised or offered by a third party on or through the Poprev
          Service or any hyperlinked website, or featured in any banner or other
          advertising. You understand and agree that Poprev is not responsible
          or liable for any transaction between you and third-party providers of
          Third-Party Applications or products or services advertised on or
          through the Poprev Service. As with any purchase of a product or
          service through any medium or in any environment, you should use your
          judgment and exercise caution where appropriate. No advice or
          information whether oral or in writing obtained by you from Poprev
          shall create any warranty on behalf of Poprev in this regard.
          <br />
          <br />
          <br />
          The user accepts that technical problems such as temporary
          non-availability of individual parts of the Services or short-term
          unavailability of the whole Service itself must be expected and shall
          not constitute breach of this Agreement. Poprev does not warrant that
          the Services will operate error-free or that the Services, its server,
          or the Services Content are free of computer viruses or similar
          contamination or destructive features. Updates to the Service may take
          place on a regular basis. Poprev reserves the right to add or remove
          support for specific devices. If your access or use of the Services or
          the Service Content results in the need for servicing or replacing
          equipment or data, Poprev shall not be responsible for those costs.
        </p>

        <h3 className="terms__body__title">
          22. Choice of Law And Dispute Resolution
        </h3>
        <p className="terms__body__text">
          The validity, construction and performance of this Agreement shall be
          governed by the laws of the Federal Republic of Nigeria.
          <br />
          <br />
          <br />
          You agree to use your best endeavour to amicably settle any dispute or
          difference of opinion arising from or in connection with this
          Agreement through mutual discussions. Where the Parties are unable to
          resolve any and all disputes through mutual discussions, the dispute
          or difference of opinion shall be referred to mediation conducted by
          our (yours and ours) legal representatives and/or financial auditors,
          where financial matters are involved.
          <br />
          <br />
          <br />
          If we are unable to arrive at a satisfactory decision at mediation,
          you agree that the dispute shall finally be referred to arbitration in
          accordance with the Arbitration and Conciliation Act Cap. A18, Laws of
          the Federation of Nigeria 2004, or any amendment thereto.
          <br />
          <br />
          <br />
          The place of arbitration shall be Lagos State, Nigeria and there shall
          be a sole arbitrator who shall be appointed jointly by the parties.
          Where the parties fail to jointly agree on the appointment, we shall
          approach the Lagos Multi-Door Court House to appoint a sole arbitrator
          who shall be competent to adjudicate on the issue in dispute. The
          language to be used in the arbitral proceedings shall be English.
          <br />
          <br />
          <br />
          The arbitrator, and not any court, shall have exclusive authority to
          resolve any Claim relating to the interpretation, applicability,
          enforceability or formation of this Arbitration Provision including,
          but not limited to any claim that all or any part of this Agreement is
          void or voidable. The arbitrator will decide any and all
          Claims—statutory, common law, or otherwise and the arbitral award
          shall be final and binding between the Parties.
          <br />
          <br />
          <br />
          The reference of any dispute to arbitration shall not prevent the
          Parties from seeking any form of protective
          reliefs/injunctions/specific performance from a court of law via
          motion or other originating processes for the protection of any res
          pending the constitution of the Arbitral Panel and/or final award.
          <br />
          <br />
          <br />
          Any and all proceedings to resolve Claims will be conducted only on an
          individual basis and not in a class, consolidated, or representative
          action or proceeding. The arbitrator may award relief (including
          monetary, injunctive, and declaratory relief) only in favour of the
          individual party seeking relief and only to the extent necessary to
          provide relief necessitated by that party’s individual claims. Any
          relief awarded cannot affect other users of the Services.
          <br />
          <br />
          <br />
          Each party will be individually responsible for all costs and fees
          incurred in connection with any mediation or arbitration, including
          without limitation, all attorneys’ fees, unless recoverable under
          applicable law. In the event that the arbitrator determines the
          claim(s) you assert in the arbitration to be frivolous, you agree to
          reimburse us for all fees and costs we incur.
        </p>

        <h3 className="terms__body__title">23. Miscellaneous</h3>
        <p className="terms__body__text">
          Should any provision of this Agreement is found to be invalid or
          unenforceable for any reason or to any extent, by any court of
          competent jurisdiction, such invalidity or unenforceability shall not
          in any manner affect or render invalid or unenforceable the remaining
          provisions of the Agreement, and the application of that provision
          shall be enforced to the extent permitted by law unless such deletion
          materially frustrates the intent of the parties, in which case this
          Agreement will terminate.
          <br />
          <br />
          <br />
          This Agreement does not imbue an agency, partnership, joint venture,
          employee- employer or franchisor-franchisee relationship between you
          and Poprev or between Poprev and any other user of the Services.
          Nothing in this Agreement is not intended to, and does not, give any
          person who is not a party to it any right to enforce any of its
          provisions.
          <br />
          <br />
          <br />
          Our failure to act on or enforce any provision of this Agreement shall
          not be construed as a waiver of that provision or any other provision
          in this Agreement. No waiver shall be effective against Poprev unless
          made in writing, and no such waiver shall be construed as a waiver in
          any other or subsequent instance.
          <br />
          <br />
          <br />
          Poprev intends to rely on this Agreement (including the Privacy
          Policy, and any notices regarding the Services sent to you or posted
          on the Services). If you require any changes to the Agreement, you
          must make a request in writing and for any agreed change(s), it must
          be expressly agreed to by you and Poprev in writing.
          <br />
          <br />
          <br />
          The section headings are provided merely for convenience and shall not
          be given any legal import. This Agreement will inure to the benefit of
          our (Poprev) successors, assigns, licensees, and sublicensees.
        </p>
      </TermsAndPolicyLayoutWrapper>
    </LandingLayout>
  );
};
export default TermAndConditions;
