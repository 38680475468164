export const validateEmail = (rule: any, value: string, setStatus: any) => {
  return new Promise(async (resolve, reject) => {
    if (!value) {
      setStatus({ error: "Please enter your email address 😇" });
      return;
    } else if (
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        value
      )
    ) {
      setStatus({
        error: "Email address entered is Invalid",
      });
      return;
    } else {
      setStatus({ success: "success" });
      resolve(value);
    }
  });
};
