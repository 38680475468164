// general layout for investor General page

import React, { useEffect, useRef, useState } from "react";
import { HeaderWrap } from "./header.styles";
import { Flex } from "antd";
import { useLocation } from "react-router-dom";
import { PopButton } from "../../lib/Button";
import { HiOutlineMenuAlt4 as MenuIcon } from "react-icons/hi";
import { throttle } from "lodash";
import PoprevEyeWithLettersLogo from "../../../assets/images/poprev-logo.png";

interface IProps {}

const Header: React.FC<IProps> = () => {
  const location = useLocation();
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [navBackground, setNavBackground] = useState(false);
  const navRef = useRef(true);
  navRef.current = navBackground;

  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 60;
      if (navRef.current !== show) {
        setNavBackground(show);
      }
    };
    document.addEventListener("scroll", throttle(handleScroll, 100));
    return () => {
      document.removeEventListener("scroll", throttle(handleScroll, 100));
    };
  }, []);

  const menuItems = [
    {
      navTitle: "Home",
      navRoute: "/",
    },
    {
      navTitle: "About us",
      navRoute: "/#about",
    },
    {
      navTitle: "Artist",
      navRoute: "https://prospects.poprev.dev/",
    },
    {
      navTitle: "Payd",
      navRoute: "https://payd.poprev.dev/",
    },
    {
      navTitle: "FAQ",
      navRoute: "/#faqs",
    },
  ];

  return (
    <HeaderWrap showMobileMenu={showMobileMenu} navBackground={navBackground}>
      <Flex className="header__wrap__main" justify="space-between">
        <div
          className="header__logo__wrap"
          onClick={() => {
            window.location.reload();
          }}
        >
          <img src={PoprevEyeWithLettersLogo} alt="" />
        </div>

        <Flex className="hearder__right__section">
          {menuItems.map((item, idx) => (
            <span
              className="menu__item"
              id={
                item.navRoute === location.pathname ? "active__menu__item" : ""
              }
              onClick={() => setShowMobileMenu(!showMobileMenu)}
              key={idx}
            >
              <a
                href={item.navRoute}
                rel="noreferrer"
                target={item.navRoute?.includes("http") ? "_blank" : ""}
              >
                {item.navTitle}
              </a>
            </span>
          ))}
          <a
            href="https://payd.poprev.dev/login"
            rel="noreferrer"
            target="_blank"
            className="register__button"
          >
            <PopButton outlined width="8rem" fontWeight="400">
              Login / Sign up
            </PopButton>
          </a>
        </Flex>

        <div
          className="mobile__menuicon"
          onClick={() => setShowMobileMenu(!showMobileMenu)}
        >
          <MenuIcon color="var(--whitePrimary)" size={30} />
        </div>
      </Flex>
    </HeaderWrap>
  );
};

export default Header;
