import React from "react";
import { IoMdClose } from "react-icons/io";
import { TermsAndPolicyLayoutWrapper } from "../../components/lib/TermsAndPolicyPagesLayout/termsAndPolicyPagesLayout.styles";
import LandingLayout from "../../components/Layout/landingLayout";

const PurchaseTerms = () => {
  return (
    <LandingLayout>
      <TermsAndPolicyLayoutWrapper>
        <h1 className="terms__header">INVESTOR TERMS AND CONDITIONS</h1>
        <p className="terms__subheader terms__subheader__alignedleft">
          Thank you for your interest in investing in music projects on PopRev!
          Please carefully review the following terms and conditions
          (&quot;Terms&quot;) before purchasing any music project coins. By
          clicking “Accept” or making a purchase, you agree to be bound by these
          Terms.
          <br />
          <br />
          <br />
          Poprev (“Poprev”, “we”, “us”, “our”) is a service provider that
          provides our members (“you” “yourself” “Users”) with access to
          investable music projects, artist and product merchandise, content
          including but not limited to access to motion pictures and other
          audio-visual entertainment (“content”) delivered over the Internet, to
          Internet enabled devices. Poprev is owned and operated by Groove
          Platforms Limited (the Company”); a company duly registered in Nigeria
          (RC 1387955).
        </p>

        <h3 className="terms__body__title">1. Investment Risks</h3>
        <p className="terms__body__text">
          1.1. Investing in music projects involves inherent risks, including
          but not limited to financial loss. PopRev does not guarantee any
          returns on your investment.
          <br />
          <br />
          1.2. You acknowledge and accept that the value of the music project
          coins may fluctuate and may result in partial or total loss of your
          investment.
          <br />
          <br />
          1.3. PopRev recommends that investors conduct their own research and
          seek independent financial advice before making any investment
          decisions.
        </p>

        <h3 className="terms__body__title">2. Investment Period</h3>
        <p className="terms__body__text">
          2.1. The maximum investment period for music project coins is 24
          months, unless otherwise specified for a particular project.
          <br />
          <br />
          2.2. Investors agree to hold their investment for the duration of the
          specified investment period and understand that early withdrawal are
          subject to holding period terms and condition.
        </p>

        <h3 className="terms__body__title">3. Returns and Payouts</h3>
        <p className="terms__body__text">
          3.1. Initial returns on investments are not expected before 90 days
          from the launch date of the music project on streaming platforms.
          <br />
          <br />
          3.2. Subsequent returns may be distributed monthly or quarterly,
          depending on the terms of each individual project.
          <br />
          <br />
          3.3. PopRev reserves the right to adjust the frequency and timing of
          payout distributions as deemed necessary.
        </p>

        <h3 className="terms__body__title">4. Coin Resale and Redemption</h3>
        <p className="terms__body__text">
          4.1. Music project coins can only be resold back to PopRev at the set
          coin price determined by PopRev.
          <br />
          <br />
          4.2. Investors may initiate the sale of their coins only from a date
          appointed by PopRev, which is typically after the first 90 days
          (holding period) of the project launch.
          <br />
          <br />
          4.3. Project launch is defined as the date the artist’s music
          (EP/Album) is released to streaming platforms
          <br />
          <br />
          4.4. PopRev reserves the right to refuse or delay the redemption or
          resale of coins at its discretion..
        </p>

        <h3 className="terms__body__title">
          5. Investor Representation and Warranties
        </h3>
        <p className="terms__body__text">
          5.1. By purchasing music project coins, investors represent and
          warrant that they have carefully reviewed these Terms and fully
          understand the risks associated with investing in music projects.
          <br />
          <br />
          5.2. Investors further represent and warrant that they are of legal
          age and have the legal capacity to enter into binding contracts.
        </p>

        <h3 className="terms__body__title">6. Limitation of Liability</h3>
        <p className="terms__body__text">
          6.1. PopRev shall not be liable for any direct, indirect, incidental,
          special, consequential, or exemplary damages, including but not
          limited to, damages for loss of profits, goodwill, use, data, or other
          intangible losses arising out of or in connection with these Terms or
          the use or inability to use the music project coins. <br />
          <br />
          6.2. In no event shall PopRev’s total liability to investors for all
          damages exceed the amount of fees paid by the investor to PopRev.
        </p>

        <h3 className="terms__body__title">7. Amendment of Terms </h3>
        <p className="terms__body__text">
          7.1. PopRev reserves the right to amend or modify these Terms at any
          time without prior notice. <br />
          <br />
          7.2. Any changes to these Terms will be effective immediately upon
          posting on the PopRev website.
        </p>
        <h3 className="terms__body__title">8. Governing Law </h3>
        <p className="terms__body__text">
          8.1. These Terms and all matters arising out of or relating to these
          Terms shall be governed by and construed in accordance with the laws
          of Nigeria.
        </p>
      </TermsAndPolicyLayoutWrapper>
    </LandingLayout>
  );
};
export default PurchaseTerms;
