import styled from "styled-components";

export const ArtistCardWrap = styled.div`
  width: 19.5rem;
  height: 30rem;
  min-width: 350px;
  border: 0.08rem solid #707070;
  border-color: #707070;
  transition: all 0.3s ease;
  cursor: pointer;

  @media (max-width: 450px) {
    width: 300px;
    min-width: 300px;
    height: 480px;
  }

  .card__image__wrap {
    padding: 15px;
    height: 73%;
    .card__image__wrap__inner {
      transition: all 0.3s ease;
      position: relative;
      height: 100%;

      .card__image {
        height: 100%;
        width: 100%;
      }
    }
  }

  .other__details__wrap {
    border-top: 1px solid #707070;
    padding: 20px 10px 10px 10px;
    border-color: #707070;
    height: 27%;
    flex-wrap: nowrap;

    .remove__btn {
      background: #a19d94;

      small {
        color: #000000;
      }
    }
    .texts__wrap__main {
      width: 100%;
      gap: 0.3rem;
    }
    .texts__wrap {
      width: 47%;

      .draftpickcards__texts {
        white-space: nowrap;
        font-size: 0.6rem;
        width: 100%;
        margin: 0;
        text-align: start;
        line-height: 1.4rem;
      }
    }

    .buttons__wrap {
      width: 100%;
      a {
        width: 50%;
      }
    }
  }

  &:hover {
    border-color: var(--yellowPrimary);
  }
`;
