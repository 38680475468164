import { Flex } from "antd";
import styled from "styled-components";

type Custom = {
  setMessage?: any;
  type?: string;
};

export const ToastMessageWrap = styled(Flex)<Custom>`
  width: 100%;
  height: max-content;
  margin: 1rem 0;
  animation: drop 0.2s 1 ease-in-out;
  position: relative;
  overflow: hidden;
  padding: 0.03rem 0.03rem 0.03rem 1rem;

  ::before {
    content: "";
    width: 0;
    height: 0.15rem;
    background-color: #fff;
    animation: ${({ setMessage }) =>
      setMessage ? "progress 10s 1 ease-in-out" : ""};
    position: absolute;
    bottom: -0.03rem;
    left: 0;
  }

  background: ${({ type }) =>
    type === "error"
      ? "#FC5D5D"
      : type === "pending"
      ? "var(--yellowPrimary)"
      : "green"};

  .text__wrap {
    background-color: var(--blackSecondary);
    width: calc(100% - 2rem);
    min-height: 2.5rem;
    padding: 0.5rem;

    p {
      margin: 0;
      font-size: 0.8rem;
      color: #fff;
      font-weight: 500;
      text-align: start;
    }
  }
  @keyframes drop {
    0% {
      transform: translateY(-3rem);
    }
    100% {
      transform: translateY(0);
    }
  }

  @keyframes progress {
    0% {
      width: 100%;
    }
    100% {
      width: 0;
    }
  }

  @media (max-width: 900px) {
    .text__wrap {
      min-height: 3rem;

      p {
        font-size: 1rem;
      }
    }
  }
`;
