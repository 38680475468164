import React from "react";
import { IoMdClose } from "react-icons/io";
import { TermsAndPolicyLayoutWrapper } from "../../components/lib/TermsAndPolicyPagesLayout/termsAndPolicyPagesLayout.styles";
import LandingLayout from "../../components/Layout/landingLayout";

const PrivacyPolicy = () => {
  return (
    <LandingLayout>
      <TermsAndPolicyLayoutWrapper>
        <h1 className="terms__header">PRIVACY POLICY</h1>
        <p className="terms__subheader terms__subheader__alignedleft">
          This Privacy Policy describes how Groove Platforms Limited (the
          “Site”, “we”, “us”, or “our”) collects, uses, and discloses your
          personal information when you visit, use our services, or make a
          contribution or transaction from https://poprev.dev/(the “Site”) also
          known as Poprev or otherwise communicate with us (collectively, the
          “Services”). For purposes of this Privacy Policy, “you” and “your”
          means you as the user of the Services, whether you are a customer,
          website visitor, or another individual whose information we have
          collected pursuant to this Privacy Policy.
          <br />
          <br />
          <br />
          Please read this Privacy Policy carefully. By using and accessing any
          of the Services, you agree to the collection, use, and disclosure of
          your information as described in this Privacy Policy. If you do not
          agree to this Privacy Policy, please do not use or access any of the
          Services. Welcome to our Terms and Conditions of Use. These terms
          affect your legal rights, endeavour to read them and treat with utmost
          importance.
        </p>

        <h3 className="terms__body__title">Changes to This Privacy Policy</h3>
        <p className="terms__body__text">
          We may update this Privacy Policy from time to time, including to
          reflect changes to our practices or for other operational, legal, or
          regulatory reasons. We will post the revised Privacy Policy on the
          Site, update the “Last updated” date and take any other steps required
          by applicable law.
        </p>

        <h3 className="terms__body__title">
          How We Collect and Use Your Personal Information
        </h3>
        <p className="terms__body__text">
          By accessing, visiting, using or browsing the Poprev Service, via this
          Site or any other device or application or other technology, you
          accept and agree to be bound by the Terms. Furthermore, you agree that
          you shall be subject to any additional terms of use, guidelines or
          rules that apply when you use certain products or Services within or
          connected to the Poprev Service, which may be posted and modified from
          time to time. All such guidelines are hereby incorporated by reference
          into the Terms and Agreement. ANY ACCESS, USE or PARTICIPATION IN THE
          POPREV SERVICE WILL CONSTITUTE TOTAL ACCEPTANCE OF THE TERMS. IF YOU
          DO NOT AGREE TO THESE TERMS OF USE, PLEASE DO NOT USE THE POPREV
          SERVICE, OUR SITE, APPLICATION OR ACCESS OUR SERVICE.
        </p>

        <h3 className="terms__body__title">
          What Personal Information We Collect
        </h3>
        <p className="terms__body__text">
          The types of personal information we obtain about you depends on how
          you interact with our Site and use our Services. When we use the term
          “personal information”, we are referring to information that
          identifies, relates to, describes or can be associated with you. The
          following sections describe the categories and specific types of
          personal information we collect.
        </p>
        <h3 className="terms__body__title">
          Information We Collect Directly from You
        </h3>
        <p className="terms__body__text">
          Information that you directly submit to us through our Services may
          include:
          <br />
          <br />
          - Basic contact details including your name, address, phone number,
          email.
          <br />
          <br />
          - Order information including your name, billing address, shipping
          address, payment confirmation, email address, phone number.
          <br />
          <br />
          - Account information including your username, password, security
          questions.
          <br />
          <br />
          - Shopping information including the items you view, put in your cart
          or add to your wishlist.
          <br />
          <br />
          - Identity verification information including BVN, Identity documents,
          PSB services information
          <br />
          <br />
          - Customer support information including the information you choose to
          include in communications with us, for example, when sending a message
          through the Services.
          <br />
          <br />
          Some features of the Services may require you to directly provide us
          with certain information about yourself. You may elect not to provide
          this information, but doing so may prevent you from using or accessing
          these features.
        </p>

        <h3 className="terms__body__title">
          Information We Collect through Cookies
        </h3>
        <p className="terms__body__text">
          We also automatically collect certain information about your
          interaction with the Services (“Usage Data”). To do this, we may use
          cookies, pixels and similar technologies (“Cookies”). Usage Data may
          include information about how you access and use our Site and your
          account, including device information, browser information,
          information about your network connection, your IP address and other
          information regarding your interaction with the Services.
        </p>

        <h3 className="terms__body__title">
          Information We Obtain from Third Parties
        </h3>
        <p className="terms__body__text">
          Poprev Services - In order to access our service, you are required to
          create an account with Poprev by formally registering with Poprev, via
          the website or application, or a third party. Here’s some information
          about all the ways you can enjoy Poprev.
        </p>
        <h3 className="terms__body__title">
          How We Use Your Personal Information
        </h3>
        <p className="terms__body__text">
          - Providing Products and Services. We use your personal information to
          provide you with the Services in order to perform our contract with
          you, including to process your participation in artist projects,
          payments, fulfill your orders, to send notifications to you related to
          you account, purchases, returns, exchanges or other transactions, to
          create, maintain and otherwise manage your account, to arrange for
          shipping, facilitate any returns and exchanges and to enable you to
          post reviews.
          <br /> <br />
          <br />
          - Marketing and Advertising. We use your personal information for
          marketing and promotional purposes, such as to send marketing,
          advertising and promotional communications by email, text message or
          postal mail, and to show you advertisements for products or services.
          This may include using your personal information to better tailor the
          Services and advertising on our Site and other websites.
          <br /> <br />
          <br />
          - Security and Fraud Prevention. We use your personal information to
          detect, investigate or take action regarding possible fraudulent,
          illegal or malicious activity. If you choose to use the Services and
          register an account, you are responsible for keeping your account
          credentials safe. We highly recommend that you do not share your
          username, password, or other access details with anyone else. If you
          believe your account has been compromised, please contact us
          immediately.
          <br /> <br />
          <br />- Communicating with you. We use your personal information to
          provide you with customer support and improve our Services. This is in
          our legitimate interests in order to be responsive to you, to provide
          effective services to you, and to maintain our business relationship
          with you.
        </p>

        <h3 className="terms__body__title">Cookies </h3>
        <p className="terms__body__text">
          Like many websites, we use Cookies on our Site. We use Cookies to
          power and improve our Site and our Services (including to remember
          your actions and preferences), to run analytics and better understand
          user interaction with the Services (in our legitimate interests to
          administer, improve and optimize the Services). We may also permit
          third parties and services providers to use Cookies on our Site to
          better tailor the services, products and advertising on our Site and
          other websites.
          <br /> <br />
          <br />
          Most browsers automatically accept Cookies by default, but you can
          choose to set your browser to remove or reject Cookies through your
          browser controls. Please keep in mind that removing or blocking
          Cookies can negatively impact your user experience and may cause some
          of the Services, including certain features and general functionality,
          to work incorrectly or no longer be available. Additionally, blocking
          Cookies may not completely prevent how we share information with third
          parties such as our advertising partners.
        </p>

        <h3 className="terms__body__title">
          How We Disclose Personal Information{" "}
        </h3>
        <p className="terms__body__text">
          In certain circumstances, we may disclose your personal information to
          third parties for legitimate purposes subject to this Privacy Policy.
          Such circumstances may include:
          <br /> <br />
          - With vendors or other third parties who perform services on our
          behalf (e.g., IT management, payment processing, data analytics,
          customer support, cloud storage, fulfillment and shipping).
          <br /> <br />
          - With our affiliates or otherwise within our corporate group, in our
          legitimate interests to run a successful business.
          <br /> <br />
          - In connection with a business transaction such as a merger or
          bankruptcy, to comply with any applicable legal obligations (including
          to respond to subpoenas, search warrants and similar requests), to
          enforce any applicable terms of service, and to protect or defend the
          Services, our rights, and the rights of our users or others.
          <br /> <br />
          <br />
          We have, in the past 12 months disclosed the following categories of
          personal information and sensitive personal information (denoted by *)
          about users for the purposes set out above in “How we Collect and Use
          your Personal Information” and “How we Disclose Personal Information”:
          <br /> <br />
          <b>Category:</b>
          <br /> <br />
          - Identifiers such as basic contact details and certain order and
          account information
          <br /> <br />
          - Commercial information such as order information, shopping
          information and customer support information
          <br /> <br />
          - Internet or other similar network activity, such as Usage Data
          <br /> <br />
          <br />
          <b>Categories of Recipients:</b>
          <br /> <br />
          - Vendors and third parties who perform services on our behalf (such
          as Internet service providers, payment processors, fulfillment
          partners, customer support partners and data analytics providers)
          <br /> <br />
          - Business and marketing partners
          <br /> <br />
          - Affiliates
          <br /> <br />
          We do not use or disclose sensitive personal information for the
          purposes of inferring characteristics about you.
          <br /> <br />
          <br />
          <b>Category of Personal Information</b>
          <br />
          <br />
          - Identifiers such as basic contact details and certain order and
          account information
          <br />
          <br />
          - Commercial information such as records of products or services
          purchased and shopping information
          <br />
          <br />
          - Internet or other similar network activity, such as Usage Data
          <br />
          <br />
          <br />
          <b>Categories of Recipients</b>
          <br />
          <br />
          - Business and marketing partners
          <br />
          <br />
          <br />
          The Services may enable you to post product reviews and other
          user-generated content. If you choose to submit user generated content
          to any public area of the Services, this content will be public and
          accessible by anyone.
          <br />
          <br />
          <br />
          We do not control who will have access to the information that you
          choose to make available to others, and cannot ensure that parties who
          have access to such information will respect your privacy or keep it
          secure. We are not responsible for the privacy or security of any
          information that you make publicly available, or for the accuracy, use
          or misuse of any information that you disclose or receive from third
          parties.
        </p>

        <h3 className="terms__body__title">User Generated Content</h3>
        <p className="terms__body__text">
          The Services may enable you to post product reviews and other
          user-generated content. If you choose to submit user generated content
          to any public area of the Services, this content will be public and
          accessible by anyone. We do not control who will have access to the
          information that you choose to make available to others, and cannot
          ensure that parties who have access to such information will respect
          your privacy or keep it secure. We are not responsible for the privacy
          or security of any information that you make publicly available, or
          for the accuracy, use or misuse of any information that you disclose
          or receive from third parties.
        </p>

        <h3 className="terms__body__title">Third Party Websites and Links</h3>
        <p className="terms__body__text">
          Our Site may provide links to websites or other online platforms
          operated by third parties. If you follow links to sites not affiliated
          or controlled by us, you should review their privacy and security
          policies and other terms and conditions. We do not guarantee and are
          not responsible for the privacy or security of such sites, including
          the accuracy, completeness, or reliability of information found on
          these sites. Information you provide on public or semi-public venues,
          including information you share on third-party social networking
          platforms may also be viewable by other users of the Services and/or
          users of those third-party platforms without limitation as to its use
          by us or by a third party. Our inclusion of such links does not, by
          itself, imply any endorsement of the content on such platforms or of
          their owners or operators, except as disclosed on the Services.
        </p>

        <h3 className="terms__body__title">Children’s Data</h3>
        <p className="terms__body__text">
          The Services are not intended to be used by children, and we do not
          knowingly collect any personal information about children. If you are
          the parent or guardian of a child who has provided us with their
          personal information, you may contact us using the contact details set
          out below to request that it be deleted.
          <br />
          <br />
          <br />
          As of the Effective Date of this Privacy Policy, we do not have actual
          knowledge that we “share” or “sell” (as those terms are defined in
          applicable law) personal information of individuals under 16 years of
          age.
        </p>

        <h3 className="terms__body__title">
          Security and Retention of Your Information
        </h3>
        <p className="terms__body__text">
          Please be aware that no security measures are perfect or impenetrable,
          and we cannot guarantee “perfect security.” In addition, any
          information you send to us may not be secure while in transit. We
          recommend that you do not use unsecure channels to communicate
          sensitive or confidential information to us.
          <br />
          <br />
          <br />
          How long we retain your personal information depends on different
          factors, such as whether we need the information to maintain your
          account, to provide the Services, comply with legal obligations,
          resolve disputes or enforce other applicable contracts and policies.
        </p>

        <h3 className="terms__body__title">Your Rights and Choices</h3>
        <p className="terms__body__text">
          Depending on where you live, you may have some or all of the rights
          listed below in relation to your personal information. However, these
          rights are not absolute, may apply only in certain circumstances and,
          in certain cases, we may decline your request as permitted by law.
          <br />
          <br />
          <br />
          - Right to Access / Know. You may have a right to request access to
          personal information that we hold about you, including details
          relating to the ways in which we use and share your information.
          <br />
          <br />
          - Right to Delete. You may have a right to request that we delete
          personal information we maintain about you. <br />
          <br />
          - Right to Correct. You may have a right to request that we correct
          inaccurate personal information we maintain about you. <br />
          <br />
          - Right of Portability. You may have a right to receive a copy of the
          personal information we hold about you and to request that we transfer
          it to a third party, in certain circumstances and with certain
          exceptions. <br />
          <br />
          - Right to Opt out of Sale or Sharing or Targeted Advertising. You may
          have a right to direct us not to “sell” or “share” your personal
          information or to opt out of the processing of your personal
          information for purposes considered to be “targeted advertising”, as
          defined in applicable privacy laws. <br />
          <br /> <br />
          Please note that if you visit our Site with the Global Privacy Control
          opt-out preference signal enabled, depending on where you are, we will
          automatically treat this as a request to opt-out of the “sale” or
          “sharing” of information for the device and browser that you use to
          visit the Site. <br />
          <br /> <br />
          - Right to Limit and/or Opt out of Use and Disclosure of Sensitive
          Personal Information. You may have a right to direct us to limit our
          use and/or disclosure of sensitive personal information to only what
          is necessary to perform the Services or provide the goods reasonably
          expected by an average individual. <br />
          <br />
          - Restriction of Processing: You may have the right to ask us to stop
          or restrict our processing of personal information. <br />
          <br />- Withdrawal of Consent: Where we rely on consent to process
          your personal information, you may have the right to withdraw this
          consent. <br />
          <br />
          - Appeal: You may have a right to appeal our decision if we decline to
          process your request. You can do so by replying directly to our
          denial. <br />
          <br />
          - Managing Communication Preferences: We may send you promotional
          emails, and you may opt out of receiving these at any time by using
          the unsubscribe option displayed in our emails to you. If you opt out,
          we may still send you non-promotional emails, such as those about your
          account or orders that you have made. <br />
          <br /> <br />
          You may exercise any of these rights where indicated on our Site or by
          contacting us using the contact details provided below. <br />
          <br /> <br />
          We will not discriminate against you for exercising any of these
          rights. We may need to collect information from you to verify your
          identity, such as your email address or account information, before
          providing a substantive response to the request. In accordance with
          applicable laws, You may designate an authorized agent to make
          requests on your behalf to exercise your rights. Before accepting such
          a request from an agent, we will require that the agent provide proof
          you have authorized them to act on your behalf, and we may need you to
          verify your identity directly with us. We will respond to your request
          in a timely manner as required under applicable law.
        </p>

        <h3 className="terms__body__title">Complaints</h3>
        <p className="terms__body__text">
          If you have complaints about how we process your personal information,
          please contact us using the contact details provided below. If you are
          not satisfied with our response to your complaint, depending on where
          you live you may have the right to appeal our decision by contacting
          us using the contact details set out below, or lodge your complaint
          with your local data protection authority.
        </p>
        <h3 className="terms__body__title">Contact</h3>
        <p className="terms__body__text">
          Should you have any questions about our privacy practices or this
          Privacy Policy, or if you would like to exercise any of the rights
          available to you, email us at{" "}
          <a href="mailto:https://legal@groove.ng">legal@groove.ng</a> or
          contact us at 6A Wole Ariyo street, Off Admiralty Way, Lekki Phase 1,
          Lekki, Nigeria, Lagos, LA, 105102, Nigeria.
        </p>
      </TermsAndPolicyLayoutWrapper>
    </LandingLayout>
  );
};
export default PrivacyPolicy;
