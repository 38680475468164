import { Flex } from "antd";
import styled from "styled-components";

export const HomePageWrap = styled(Flex)`
  background-color: var(--blackPrimary);
  overflow-x: hidden;

  a {
    cursor: pointer;
  }

  .yellow__header__subtext {
    font-size: 0.75rem;
    letter-spacing: 0.3rem;
    color: var(--yellowPrimary);
    font-weight: 300;
  }

  /* hero section */
  .hero__section {
    align-items: flex-start;
    padding-left: 8rem;
    position: relative;
    height: 100vh;
    width: 100%;
    .hero__left__wrap {
      max-width: 33rem;
      z-index: 2;
      height: 100%;
      padding-top: 17rem;

      .hero__header__text {
        font-size: 3rem;
        line-height: 3.3rem;
      }
      .hero__body {
        text-align: start;
        margin-bottom: 2rem;
        font-size: 1rem;
        line-height: 1.5rem;
      }
    }
    .hero__image__wrap {
      position: absolute;
      top: 0;
      width: 100%;
      left: 0;
      height: 100%;
    }
  }
  /* carousel section */
  .carousel__section {
    padding: 2rem 0;
    overflow-x: hidden;

    .carousel__header {
      letter-spacing: 0.1rem;
    }
    .carousel__wrap {
      overflow-x: scroll;
      overflow-y: hidden;
      padding-bottom: 1rem;

      & > div {
        margin: 1rem 0;
        justify-content: flex-start;
        animation: carousel 30s infinite ease-in-out;
        justify-content: flex-start;
        animation-play-state: running;

        &:hover {
          animation-play-state: paused;
        }
      }
    }
  }

  /* diversify section */
  .diversify__section {
    align-items: flex-end;
    padding: 3rem 15rem;
    gap: 5rem;

    .diversify__image__wrap {
      background-color: var(--blackSecondaryTwo);
      width: 22rem;
      height: 22rem;
      border-radius: 0.3rem;
      overflow: hidden;
    }
    .diversify__image__wrapmobile {
      display: none;
    }
    .diversify__body__wrap {
      position: relative;
      max-width: 23.5rem;
      z-index: 2;

      .diversify__header__text {
        text-align: start;
      }
      .diversify__body {
        text-align: start;
        margin-bottom: 2rem;
        font-size: 1rem;
        line-height: 1.5rem;
      }
    }
  }

  /* contibutor artist section */
  .contibutor__artist__cards__wrap {
    padding: 10rem 8rem;
    gap: 2rem;
    justify-content: center;
    .contibutor__artist__card {
      border-radius: 0.3rem;
      background-color: var(--blackSecondaryTwo);
      width: 34.5rem;
      padding: 1rem 2.5rem 2rem 2.5rem;
      flex-direction: column;
      align-items: center;
      .contibutor__artist__card__header {
        font-weight: 700;
        font-size: 2.5rem;
      }
      .contibutor__artist__card__body {
        font-size: 1rem;
        line-height: 1.8rem;
        min-height: 9rem;
      }
    }
    @media screen and (max-width: 1200px) {
      flex-direction: column;
      padding: 3rem 0;
    }
  }

  /* music enthusiast */
  .music__enthusiast__section {
    padding: 0 15rem 10rem 15rem;
    align-items: center;

    .music__enthusiast__body__wrap {
      max-width: 21rem;

      ol {
        padding: 1rem 0 0 1rem;
      }
    }

    .double__image__wrapmobile {
      display: none;
    }

    .double__image__wrap {
      width: 29rem;
      height: 29rem;
      position: relative;
      .double__image__large {
        width: 85%;
        height: 100%;
        z-index: 1;
        border-radius: 0.3rem;
        overflow: hidden;
      }
      .double__image__small {
        position: absolute;
        z-index: 2;
        width: 13rem;
        height: 13rem;
        border-radius: 0.3rem;
        overflow: hidden;
        right: 0rem;
        bottom: 2rem;
      }
    }
  }

  /* best kept section */
  .bestkept__wrap {
    max-width: 56rem;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    background: linear-gradient(86deg, #08e388 2.01%, #fdc228 99.7%);
    .bestkept__header {
      font-weight: 700;
      color: var(--blackPrimary);
      font-size: 2.5rem;
    }
    .bestkept__body {
      font-size: 1rem;
      color: var(--blackPrimary);
      font-weight: 400;
      margin-bottom: 1.5rem;
    }
  }

  /* market size section */
  .marketsize__wrap {
    padding: 7rem 8rem;
    gap: 0.5rem;
    width: 100%;
    .solution__missiontext {
      font-size: 1.1rem;
      font-weight: bold;
    }
    .marketsize__header {
      font-weight: 700;
      margin: 0;
      font-size: 2.5rem;
    }
    .marketsize__subheader {
      font-size: 1rem;
      max-width: 33rem;
      text-align: start;
      line-height: 1.3rem;
    }
    .marketsize__valuations__wrap {
      gap: 1rem;
      padding: 0.5rem 0;
      justify-content: space-between;
      width: 100%;
      .marketsize__valuations__main {
        max-width: 18rem;
        .marketsize__amount {
          font-size: 2.3rem;
          color: var(--yellowPrimary);
          margin: 0 0 0.8rem 0;
          white-space: nowrap;
        }
        .marketsize__valuations__subheader {
          text-align: start;
          font-size: 0.9rem;
          margin: 0;
          line-height: 1.8rem;
        }
        .marketsize__valuation__body {
          text-align: left;
          font-size: 0.9rem;
          line-height: 1.4rem;
        }
      }
    }
  }

  .draftpick__section__wrap {
    width: 100%;
    padding: 0 0 6rem 8rem;
    .draftpick__header__section {
      width: 100%;
      padding-right: 8rem;

      .draftpick__header__left {
        .draftpick__headertext {
          font-weight: 700;
          margin: 0;
          font-size: 2.5rem;
          text-align: left;
        }
        .draftpick__subheader {
          font-size: 0.9rem;
          text-align: start;
          line-height: 1.8rem;
          margin: 0;
        }
      }
      button {
        margin-bottom: 0.7rem;
      }
    }
    .draftpicks__cards__wrap {
      overflow-y: auto;
      padding: 2rem 0;
      width: 100%;
    }
  }

  /* whypoprev section */
  .why__poprev__section {
    padding: 3rem 0 3rem 8rem;
    width: 100%;
    justify-content: space-between;

    .why__poprev__tabssection {
      width: 100%;
      padding-right: 8rem;
      align-items: flex-end;
      .why__poprev__tabsmain {
        .tab__item {
          border: 0.1rem solid #707070;
          border-radius: 0.3rem;
          font-size: 0.8rem;
          padding: 0.3rem 0.7rem;
          line-height: normal;
          height: max-content;
          margin: 0;
          cursor: pointer;
          transition: all 0.3s ease;

          &:hover {
            transform: scale(1.05);
          }
        }
        .active__whypoprev__tab {
          border: 0.1rem solid var(--yellowPrimary);
          transition: all 0.3s ease;
          &:hover {
            transform: scale(1);
          }
        }
      }

      svg {
        cursor: pointer;
        margin-bottom: -0.3rem;
      }
    }

    .whypoprev__subcards__wrap {
      width: calc(100% - 20rem);
      scroll-behavior: smooth;
      overflow-x: hidden;
    }
    .whypopre__card__wrapmain {
      width: 100%;
    }

    .whypopre__card {
      width: 22rem;
      padding: 1.2rem;
      border: 0.1rem solid #707070;
      transition: all 0.3s ease;
      border-radius: 0.1rem;
      margin-bottom: 3rem;

      .whypoprev__cardbody {
        font-size: 1rem;
        text-align: left;
        line-height: 1.4rem;
      }
      svg {
        width: 2.6rem;
      }

      .whyporev__cardheader {
        font-size: 1.5rem;
        text-align: left;
        min-height: 4rem;
        margin: 1rem 0;
      }
    }

    .whypopre__card__mainhead {
      border: none;
      padding: 0 1.2rem;

      .yellow__header__subtext {
        font-size: 0.9rem;
      }
      .whyporev__mainheader {
        font-weight: 700;
        font-size: 2.5rem;
        text-align: start;
        line-height: 3rem;
      }
    }

    .active__whypoprev__card {
      border: 0.1rem solid var(--yellowPrimary);
      transition: all 0.3s ease;
    }
  }

  /* secutity section  */
  .security__section {
    padding: 2rem 16%;
    margin: 3rem 0;
    background-image: linear-gradient(86deg, #08e388 2.01%, #fdc228 99.7%);
    position: relative;
    width: 100%;
    align-items: center;
    .security__image__lines {
      position: absolute;
      width: 12%;
      left: 0;
      height: 100%;
    }

    .security__leftsection {
      max-width: 21rem;
      .security__image__mobile {
        width: 100%;
        display: none;
      }
      .seurity__header {
        font-size: 2.8rem;
        color: var(--blackPrimary);
        text-align: start;
        font-weight: 700;
        width: 100%;
      }
      .security__sbheader {
        font-size: 1.4rem;
        line-height: 1.8rem;
        color: var(--blackPrimary);
        text-align: left;
      }
      .security__bodytext {
        color: var(--blackPrimary);
        text-align: left;
        font-size: 0.93rem;
        font-weight: 400;
      }
    }

    .security__image__web {
      width: 29rem;
    }
  }

  /* faqs */
  .faqs__wrap {
    padding: 3rem 15px 6rem 15px;
    max-width: 52rem;
    align-items: start;
    width: 100%;
    .faqs__header {
      font-size: 2.5rem;
      font-weight: 700;
    }
    .faq__item_wrap {
      padding: 0.5rem 0 1.5rem 0;
      border-bottom: 0.1rem solid var(--blackSecondaryTwo);
      width: 100%;
      .faq___item__header__wrap {
        justify-content: space-between;
        width: 100%;
        padding: 0 1rem;

        .faq__item__headertext {
          font-size: 1rem;
          line-height: 1.4rem;
          max-width: 90%;
          text-align: start;
          margin: 0;
        }
        svg {
          cursor: pointer;
          transition: all 0.3s ease;
          :hover {
            transform: scale(1.1);
          }
        }
      }
      .faq__body {
        text-align: left;
        margin: 0;
        padding: 1.5rem 1rem 0 1rem;
        display: none;
      }
    }
    #active__faq {
      .faq__body {
        display: block;
      }
    }

    .didntfinqfad__wrap {
      align-items: center;
      margin-top: 1rem;
      width: 100%;
      .didntfind__text {
        margin: 0;
        font-size: 1rem;
      }
    }
  }

  /* disclaimer section */
  .disclaimer__wrap {
    padding: 0 9rem 6rem 9rem;
    .disclamer__body {
      text-align: start;
      font-size: 1rem;
      max-width: 50rem;
      line-height: 1.4rem;
    }
  }
  @media screen and (max-width: 1050px) {
    .hero__section {
      align-items: flex-start;
      padding-left: 3rem;
    }

    .diversify__section {
      padding: 3rem;
      .diversify__image__wrapmobile {
        display: flex;
      }
      .diversify__image__wrapweb {
        display: none;
      }

      .diversify__body__wrap {
        max-width: 100%;
        z-index: 2;

        .diversify__header__text {
          line-height: 3rem;
          font-size: 2.3rem;
        }
        .diversify__body {
          margin: 2rem 0;
          font-size: 1.2rem;
          line-height: 1.8rem;
        }
        .diversify__image__wrap {
          position: relative;
          left: 0;
          width: 100%;
          button {
            width: 100%;
            height: 3rem;
          }
        }
      }
    }
    /* music enthusiast */
    .music__enthusiast__section {
      padding: 0 3rem 3rem 3rem;
      .double__image__wrap {
        width: 100%;
        height: 29rem;
      }

      .double__image__wrapmobile {
        display: flex;
      }
      .double__image__wrapweb {
        display: none;
      }
      .music__enthusiast__body__wrap {
      }
    }

    /* market size section */
    .marketsize__wrap {
      padding: 7rem 15px;

      .marketsize__subheader {
        margin: 0.6rem 0 1.2rem 0;
      }
      .marketsize__valuations__wrap {
        .marketsize__valuations__main {
          max-width: 18rem;
          .marketsize__amount {
            font-size: 1.5rem;
          }
          .marketsize__valuations__subheader {
            font-size: 0.7rem;
          }
          .marketsize__valuation__body {
            font-size: 0.7rem;
            line-height: 1.5rem;
          }
        }
      }
    }

    .draftpick__section__wrap {
      padding: 0 0 7rem 15px;
      position: relative;
      .draftpick__header__section {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        padding-right: 15px;

        button {
          margin-bottom: 0.7rem;
          position: absolute;
          bottom: 3rem;
          left: 15px;
          width: calc(100% - 30px);
        }
      }
      .draftpicks__cards__wrap {
        overflow-y: auto;
        padding: 2rem 0;
      }
    }
    /* whypoprev section */
    .why__poprev__section {
      padding: 3rem 0;
      width: 100%;
      gap: 0;
      justify-content: space-between;
      position: relative;
      .whypoprev__subcards__wrap {
        width: 100%;
      }
      .whypopre__card {
        width: 15rem;
      }

      .why__poprev__tabssection {
        padding: 0 15px;

        .why__poprev__tabsmain {
          .tab__item {
            font-size: 0.6rem;
          }
        }
      }
    }

    /* secutity section  */
    .security__section {
      padding: 2rem 15px;

      .security__image__lines {
        display: none;
      }
    }
    .disclaimer__wrap {
      padding: 0 15px 6rem 15px;
    }
  }

  @media screen and (max-width: 800px) {
    button,
    a {
      width: 100%;
      height: 3rem;
      span {
        font-size: 1rem;
      }
    }
    .hero__section {
      align-items: flex-start;
      padding: 0 15px;
      height: 95vh;

      .hero__left__wrap {
        padding-top: 2rem;
        justify-content: center;
        max-width: 100%;

        .hero__header__text {
          font-size: 3.2rem;
          line-height: 3.8rem;
        }
        .hero__body {
          font-size: 1.1rem;
          line-height: 1.7rem;
        }

        .hero__buttons {
          flex-direction: column;
          width: 100%;
        }
      }
    }
    .diversify__section,
    .music__enthusiast__section {
      padding: 3rem 15px;
    }

    /* best kept section */
    .bestkept__wrap {
      .bestkept__body {
        font-size: 1rem;
        max-width: 80%;
      }
    }

    /* whypoprev section */
    .why__poprev__section {
      padding: 1rem 0;
      flex-direction: column-reverse;
      .why__poprev__tabssection {
        align-items: flex-start;
        flex-direction: column;
        position: absolute;
        left: 0;
        top: 22rem;

        svg {
          width: 2rem;
        }
      }

      .whypopre__card__mainhead {
        width: 100%;
      }
      .whypopre__card__wrapmain {
        flex-direction: column;
      }
      .whypoprev__subcards__wrap {
        width: 100%;
        padding-left: 15px;
        margin-top: 5rem;

        .whypopre__card {
          width: 20rem;
          margin: 0;
        }
      }
    }

    .security__section {
      .security__image__web {
        display: none;
      }
      .security__leftsection {
        max-width: 100%;
        align-items: center;

        .security__image__mobile {
          display: flex;
          margin: 1rem 0;
          max-width: 20rem;
        }
      }
    }

    .faqs__wrap {
      .faq__item_wrap {
        .faq___item__header__wrap {
          padding: 0;
        }
        .faq__body {
          padding: 1.5rem 0 0 0;
        }
      }
      .didntfinqfad__wrap {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }

  @media screen and (max-width: 500px) {
    /* market size section */
    .marketsize__wrap {
      padding: 5rem 15px;
      .marketsize__valuations__wrap {
        flex-direction: column;
        .marketsize__valuations__main {
          max-width: 90%;
          .marketsize__amount {
            font-size: 2rem;
          }
          .marketsize__valuations__subheader {
            font-size: 1rem;
          }
          .marketsize__valuation__body {
            font-size: 1rem;
          }
        }
      }
    }
  }

  @keyframes carousel {
    0% {
      transform: translateX(3000px);
    }
    100% {
      transform: translateX(0);
    }
  }
`;
