// general layout for investor General page

import React, { useState } from "react";
import { FooterWrap } from "./footer.styles";
import { Flex, Form } from "antd";
import { PopButton } from "../../lib/Button";
import { TextField } from "../../lib/TextField";
import { validateEmail } from "../../../utils/function-helpers/form-validators";
import { BsFacebook, BsInstagram, BsTiktok, BsTwitter } from "react-icons/bs";
import PoprevEyeWithLettersLogo from "../../../assets/images/poprev-logo.png";
import { joinNewsletter } from "../../../network/artist";
import ToastMessage from "../../lib/ToastMessages/toastMessages";
import { useLocation } from "react-router-dom";

const Footer = () => {
  const [isSubmitting, setIsSubmtting] = useState(false);
  const location = useLocation();
  const [form] = Form.useForm();
  const [toastMessage, setToastMessage] = useState({
    type: "",
    text: "",
  });

  const menuItems = [
    {
      title: "PopRev",
      navItems: [
        {
          navTitle: "Browse Projects",
          navRoute: "https://payd.poprev.dev/#scroll_view",
        },
        {
          navTitle: "Login / SignUp",
          navRoute: "https://payd.poprev.dev/signup",
        },
      ],
    },
    {
      title: "About Us",
      navItems: [
        {
          navTitle: "Our Story",
          navRoute: "/#about",
        },
        {
          navTitle: "Groove Platforms",
          navRoute: "https://groove.ng/",
        },
        {
          navTitle: "uduXMusic",
          navRoute: "https://udux.com/",
        },
      ],
    },

    {
      title: "Support",
      navItems: [
        {
          navTitle: "FAQ's",
          navRoute: "https://payd.poprev.dev/faq",
        },
        {
          navTitle: "Help Desk",
          navRoute: "https://payd.poprev.dev/helpdesk",
        },
        {
          navTitle: "Get in Touch",
          navRoute: "mailto:hello@wearepoprev.com",
        },
      ],
    },
  ];

  const ectraLinkItems = [
    {
      navTitle: "Privacy Policy",
      navRoute: "/privacy-policy",
    },
    {
      navTitle: "T&C's",
      navRoute: "/t&c",
    },

    {
      navTitle: "Purchase Terms",
      navRoute: "/purchase-terms",
    },
    {
      navTitle: "Cookie Policy",
      navRoute: "/cookie-policy",
    },
  ];

  const handleJoinNewsLetter = async (values: Record<string, any>) => {
    setIsSubmtting(true);
    try {
      await joinNewsletter({ ...values });
      form.setFieldsValue({ email: "" });
      setIsSubmtting(false);
      setToastMessage({
        type: "success",
        text: "You have successfully opted in for poprev's newsletter",
      });
    } catch (error: any) {
      setIsSubmtting(false);
      if (error?.response) {
        setToastMessage({
          type: "error",
          text: error?.response?.data?.message,
        });
      } else {
        setToastMessage({
          type: "error",
          text: "There was an issue with your network. Pls try again later",
        });
      }
    }
  };

  return (
    <FooterWrap vertical>
      <Flex className="footer__topsection" vertical gap="2rem">
        <Flex
          justify="space-between"
          gap="1rem"
          align="flex-start"
          className="footer__topsection__upwrap"
        >
          <Flex className="footernav__items__wrap" justify="space-between">
            {menuItems.map((item, idx) => (
              <Flex key={idx} vertical gap="1rem" className="footernav__item">
                <span className="header__text">{item.title}</span>
                {item.navItems.map((subItem, subIdx) => (
                  <span key={subIdx} className="body__text">
                    <a
                      href={subItem.navRoute}
                      target={
                        subItem.navRoute.includes("https") ? "_blank" : ""
                      }
                      rel="noreferrer"
                    >
                      {subItem.navTitle}
                    </a>
                  </span>
                ))}
              </Flex>
            ))}
          </Flex>
          <Flex
            className="footer__logo__wrap"
            onClick={() => window.scrollTo(0, 0)}
          >
            <img src={PoprevEyeWithLettersLogo} alt="" />
          </Flex>
          <Flex
            className="constactus__wrap"
            vertical
            align="flex-start"
            gap="1rem"
          >
            <span className="header__text">Stay Connected</span>
            <span className="body__text">
              Be a part of the PopRev family and receive news, update on the
              world music investment.
            </span>
            <Form onFinish={handleJoinNewsLetter} form={form}>
              {!!toastMessage?.type && (
                <ToastMessage
                  message={toastMessage}
                  setMessage={setToastMessage}
                />
              )}
              <Flex vertical gap="1rem" className="form_wrapsub">
                <TextField
                  validator={validateEmail}
                  placeholder="Enter Email"
                  name="email"
                />
                <PopButton
                  width="8rem"
                  loading={isSubmitting}
                  htmlType="submit"
                >
                  Sign up
                </PopButton>
              </Flex>
            </Form>
          </Flex>
        </Flex>

        <span className="body__text">
          PopRev PayD and Prospects are registered trademarks of Groove
          Platforms Ltd.
        </span>
      </Flex>

      <Flex
        className="footer__bottomsection"
        justify="space-between"
        gap="1rem"
      >
        <Flex
          justify="space-between"
          gap="1rem"
          align="flex-start"
          className="socials__wrap"
        >
          <a
            href="https://twitter.com/wearepoprev"
            target="_blank"
            rel="noreferrer"
          >
            <BsTwitter />
          </a>
          <a
            href="https://www.instagram.com/wearepoprev/"
            target="_blank"
            rel="noreferrer"
          >
            <BsInstagram />
          </a>
          <a
            href="https://web.facebook.com/wearepoprev"
            target="_blank"
            rel="noreferrer"
          >
            <BsFacebook />
          </a>
          <a
            href="https://www.tiktok.com/@poprev"
            target="_blank"
            rel="noreferrer"
          >
            <BsTiktok />
          </a>
        </Flex>
        <Flex className="extralinks__wrap">
          {ectraLinkItems.map((item, idx) => (
            <span key={idx} className="body__text">
              <a
                href={item.navRoute}
                id={location.pathname === item.navRoute ? "active__route" : ""}
              >
                {item.navTitle}
              </a>
            </span>
          ))}
        </Flex>
      </Flex>
    </FooterWrap>
  );
};

export default Footer;
