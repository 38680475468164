import React, { useCallback, useEffect, useRef, useState } from "react";
import { Flex } from "antd";
import { HomePageWrap } from "./homepage.styles";
import LandingLayout from "../../components/Layout/landingLayout";
import { PopButton } from "../../components/lib/Button";
import ReactPlayer from "react-player";
import { featuredList } from "../../utils/data-helpers/data";
import FeaturedProjectCard from "../../components/lib/FeaturedProjectsCard/featuredCard";
import EnthusiastLarge from "../../assets/images/music_enthusiast_large.png";
import EnthusiastSmall from "../../assets/images/music_enthusiast_small.png";
import ArtistLarge from "../../assets/images/artist-large.png";
import ArtistSmall from "../../assets/images/artist_small.png";
import SecurityImage from "../../assets/images/security_image.png";
import SecurityLines from "../../assets/images/security-imagelines.svg";
import ArtistCard from "../../components/lib/DraftpickCard/draftpickCard";
import {
  getDraftPicks,
  getFAQs,
  getFeaturedDraftPicks,
} from "../../network/artist";
import { useQuery } from "react-query";
import {
  AccessibleIcon,
  ArrowLeftSquareOutline,
  ArrowRightSquareOutline,
  DirectArtistIcon,
  InnovativeRevenueIcon,
  MusicCentricIcon,
  TransparencyIcon,
} from "../../assets/images/svgExports";
import { HiMinus, HiPlus } from "react-icons/hi";
import { convertToRaw, convertFromRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { convert } from "html-to-text";

const HomePage = () => {
  const [isPlaying, setIsPlaying] = useState(true); // Start with playing true
  const [canAutoplay, setCanAutoplay] = useState(true);
  const [activeWhyPoprevcard, setActiveWhyPoprevcard] = useState(0);
  const [activeFaq, setActiveFaq] = useState(-1);
  const landingDraftPickCardRef = useRef<any>({});

  const { data: draftPicksData } = useQuery(
    ["landingdraftpicks"],
    getDraftPicks
  );

  const { data: featuredDraftPicksData } = useQuery(
    ["featuredDraftpicks"],
    getFeaturedDraftPicks
  );
  const { data: faqData } = useQuery(["faqs"], getFAQs);

  useEffect(() => {
    // Check if the video can autoplay
    const videoElement = document.createElement("video");
    videoElement.src =
      "https://res.cloudinary.com/poprev/video/upload/v1660565324/Landing-Page/signup-video_ao4vyc.mp4";
    videoElement.muted = true;
    videoElement.playsInline = true;
    videoElement.autoplay = true;

    const checkAutoplay = async () => {
      try {
        await videoElement.play();
        setCanAutoplay(true);
      } catch (error) {
        setCanAutoplay(false);
      }
    };

    checkAutoplay();
  }, []);

  const convertTermsToHTML = (stringifiedHtmlContent: any) => {
    const convertedState = convertFromRaw(JSON.parse(stringifiedHtmlContent));
    const editorState = EditorState.createWithContent(convertedState);

    const res = `${draftToHtml(convertToRaw(editorState.getCurrentContent()))}`;
    return res || "";
  };

  const whyPoprevData = [
    {
      title: "Music-Centric Engagement:",
      description:
        "PopRev lets music fans invest in upcoming albums/EPs with digital tokens, offering a unique way to support artists and share profits.",
      icon: <MusicCentricIcon />,
      tabTitle: "Engagement",
    },
    {
      title: "Direct Artist Support:",
      description:
        "PopRev lets fans invest in artists' music, funding their projects and sharing profits based on success.",
      icon: <DirectArtistIcon />,
      tabTitle: "Support",
    },
    {
      title: "Innovative Revenue Sharing:",
      description:
        "PopRev flips the script: fans invest, share music profits. It's a win-win for artists and supporters.",
      icon: <InnovativeRevenueIcon />,
      tabTitle: "Revenue",
    },
    {
      title: "Transparency and Engagement:",
      description:
        "PopRev fans see it all (project details, forecasts, updates) for a trusted music community.",
      icon: <TransparencyIcon />,
      tabTitle: "Transparency",
    },
    {
      title: "Accessible Participation Opportunities:",
      description:
        "PopRev welcomes all music fans. Invest in artists you love, no matter your experience level.",
      icon: <AccessibleIcon />,
      tabTitle: "Accessibility",
    },
  ];

  const scrollIntoView = (id: number) => {
    const element = document.getElementById("whyporev__card" + id);
    if (element)
      element.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    setActiveWhyPoprevcard(id);
  };

  return (
    <LandingLayout>
      <HomePageWrap vertical align="center" id="homepage__wrap">
        {/* hero section */}
        <Flex justify="space-between" className="hero__section">
          <Flex className="hero__left__wrap" vertical align="start">
            <h1 className="hero__header__text">
              Discover the Exciting World of Music Royalties
            </h1>
            <span className="hero__body">
              Experience the future of music participation with poprev. We offer
              a unique opportunity for enthusiasts and music artists to come
              together and revolutionize the industry. Join us today and be part
              of the next big thing in music, Navigate to the Payd section below
              to sign up and start participating in music projects
            </span>
            <Flex className="hero__buttons" gap="1rem">
              <a
                href="https://payd.poprev.dev/"
                rel="noreferrer"
                target="_blank"
              >
                <PopButton>Start Now</PopButton>
              </a>
              <a
                href="https://prospects.poprev.dev/"
                rel="noreferrer"
                target="_blank"
              >
                <PopButton outlined>Artist</PopButton>
              </a>
            </Flex>
          </Flex>
          <Flex className="hero__image__wrap">
            {canAutoplay ? (
              <ReactPlayer
                url="https://res.cloudinary.com/poprev/video/upload/v1660565295/Landing-Page/Sequence_01_1_1_crdeh2.mp4"
                width={"100%"}
                height={"100%"}
                loop={true}
                muted={true}
                playsinline={true}
                controls={false}
                playing={isPlaying}
              />
            ) : (
              <img src="../ezgif-1-0633618962.gif" alt="" />
            )}
          </Flex>
        </Flex>

        {/* carousel section */}
        <Flex className="carousel__section" vertical align="center">
          <h3 className="carousel__header">FEATURED MUSIC PROJECTS</h3>
          <Flex className="carousel__wrap">
            <Flex gap="1rem">
              {featuredList.map((item, idx) => (
                <Flex gap="1rem" key={idx}>
                  {featuredDraftPicksData?.data?.map(
                    (item: Record<string, any>, idx: number) => (
                      <FeaturedProjectCard key={idx} cardData={item} />
                    )
                  )}
                </Flex>
              ))}
            </Flex>
          </Flex>
        </Flex>

        <Flex justify="center" className="diversify__section">
          <Flex className="diversify__image__wrap  diversify__image__wrapweb">
            {canAutoplay ? (
              <ReactPlayer
                url="https://res.cloudinary.com/poprev/video/upload/v1660565324/Landing-Page/signup-video_ao4vyc.mp4"
                width={"100%"}
                height={"100%"}
                loop={true}
                muted={true}
                playsinline={true}
                controls={false}
                playing={isPlaying}
              />
            ) : (
              <img src="../ezgif-2-c42ac0ea61.gif" alt="" />
            )}
          </Flex>
          <Flex className="diversify__body__wrap" vertical align="start">
            <h2 className="yellow__header__subtext">DIVERSIFYING WEALTH</h2>
            <h2 className="diversify__header__text">
              Participate in <br />
              supporting your favorite music albums through the Payd section.
            </h2>
            <Flex className="diversify__image__wrap diversify__image__wrapmobile">
              {canAutoplay ? (
                <ReactPlayer
                  url="https://res.cloudinary.com/poprev/video/upload/v1660565324/Landing-Page/signup-video_ao4vyc.mp4"
                  width={"100%"}
                  height={"100%"}
                  loop={true}
                  muted={true}
                  playsinline={true}
                  controls={false}
                  playing={isPlaying}
                />
              ) : (
                <img src="../ezgif-2-c42ac0ea61.gif" alt="" />
              )}
            </Flex>
            <p className="diversify__body">
              Poprev is a revolutionary music tokenization platform where fans,
              music enthusiasts, and the public can own a stake in EPs/Albums by
              supporting selected projects known as 'draft picks
            </p>
            <a href="https://payd.poprev.dev/" rel="noreferrer" target="_blank">
              <PopButton width="7.2rem">Get Started</PopButton>
            </a>
          </Flex>
        </Flex>

        {/* payd/prospecs cards section */}
        <Flex className="contibutor__artist__cards__wrap">
          <Flex className="contibutor__artist__card">
            <h2 className="yellow__header__subtext">PAYD</h2>
            <h2 className="contibutor__artist__card__header">CONTRIBUTOR</h2>
            <p className="contibutor__artist__card__body">
              We present exciting music participation opportunities to earn from
              artists’ projects through streaming revenue. By supporting PopRev
              artists, you expand your portfolio, empower talented musicians,
              and shape the future of the African music landscape.
            </p>
            <a
              href="https://payd.poprev.dev/signup"
              rel="noreferrer"
              target="_blank"
            >
              <PopButton width="7.8rem">Sign up now </PopButton>
            </a>
          </Flex>

          <Flex className="contibutor__artist__card">
            <h2 className="yellow__header__subtext">PROSPECTS</h2>
            <h2 className="contibutor__artist__card__header">ARTIST</h2>
            <p className="contibutor__artist__card__body">
              Share your music project with PopRev to potentially become a
              'Draft Pick' and gain access to funding for marketing and
              promotion. Take your music career to new heights with our
              assistance and connect with a lively community of music
              <br />
              enthusiasts.
            </p>
            <a
              href="https://prospects.poprev.dev/"
              rel="noreferrer"
              target="_blank"
            >
              <PopButton width="11rem">Submit Your Project</PopButton>
            </a>
          </Flex>
        </Flex>

        {/* music enthusiast section */}
        <Flex
          justify="flex-end"
          className="music__enthusiast__section diversify__section"
        >
          <Flex className="double__image__wrap double__image__wrapweb">
            <Flex className="double__image__large">
              <img src={EnthusiastLarge} alt="" />
            </Flex>
            <Flex className="double__image__small">
              <img src={EnthusiastSmall} alt="" />
            </Flex>
          </Flex>
          <Flex
            className="music__enthusiast__body__wrap diversify__body__wrap"
            vertical
            align="start"
          >
            <h2 className="yellow__header__subtext">
              ARE YOU A MUSIC ENTHUSIAST?
            </h2>
            <h2 className="music__enthusiast__header__text diversify__header__text">
              Start building your music portfolio in three easy steps
            </h2>
            <Flex className="double__image__wrap double__image__wrapmobile">
              <Flex className="double__image__large">
                <img src={EnthusiastLarge} alt="" />
              </Flex>
              <Flex className="double__image__small">
                <img src={EnthusiastSmall} alt="" />
              </Flex>
            </Flex>
            <ol className="enthusial__list">
              <li className="enthusiast__body diversify__body">
                Sign up within the PAYD interface.
              </li>
              <li className="enthusiast__body diversify__body">
                Set up your profile and verify to Level 2.
              </li>
              <li className="enthusiast__body diversify__body">
                Browse the range of music projects, participate, and contribute.
              </li>
            </ol>
            <a
              href="https://payd.poprev.dev/signup"
              rel="noreferrer"
              target="_blank"
            >
              <PopButton
                width="7.2rem"
                backgroundColor="var(--whitePrimary)"
                borderColor="var(--whitePrimary)"
              >
                Sign up now{" "}
              </PopButton>
            </a>
          </Flex>
        </Flex>

        {/* music enthusiast section */}
        <Flex
          justify="flex-end"
          className="music__enthusiast__section diversify__section"
        >
          <Flex
            className="music__enthusiast__body__wrap diversify__body__wrap"
            vertical
            align="start"
          >
            <h2 className="yellow__header__subtext">HOW IT WORKS</h2>
            <h2 className="music__enthusiast__header__text diversify__header__text">
              Are you an artist, songwriter, or rights holder?
            </h2>
            <Flex className="double__image__wrap double__image__wrapmobile">
              <Flex className="double__image__large">
                <img src={ArtistLarge} alt="" />
              </Flex>
              <Flex className="double__image__small">
                <img src={ArtistSmall} alt="" />
              </Flex>
            </Flex>
            <ol className="enthusial__list">
              <li className="enthusiast__body diversify__body">
                Submit your music project to PopRev for a chance to become a
                'Draft Pick’.
              </li>
              <li className="enthusiast__body diversify__body">
                Poprev team reviews project and music rights
              </li>
              <li className="enthusiast__body diversify__body">
                Get approved as a draft pick and elevate your music career
              </li>
            </ol>

            <a
              href="https://prospects.poprev.dev/"
              rel="noreferrer"
              target="_blank"
            >
              <PopButton
                width="9rem"
                backgroundColor="var(--whitePrimary)"
                borderColor="var(--whitePrimary)"
              >
                Submit Project
              </PopButton>
            </a>
          </Flex>

          <Flex className="double__image__wrap double__image__wrapweb">
            <Flex className="double__image__large">
              <img src={ArtistLarge} alt="" />
            </Flex>
            <Flex className="double__image__small">
              <img src={ArtistSmall} alt="" />
            </Flex>
          </Flex>
        </Flex>

        {/* best kept secret section*/}
        <Flex className="bestkept__wrap">
          <h2 className="bestkept__header">The best kept secret in music</h2>
          <p className="bestkept__body">
            Browse and contribute to a wide range of music album shares
          </p>
          <a
            href="https://payd.poprev.dev/#scroll_view"
            rel="noreferrer"
            target="_blank"
          >
            <PopButton
              width="10rem"
              backgroundColor="var(--blackPrimary)"
              borderColor="var(--blackPrimary)"
              color="var(--whitePrimary)"
            >
              Browse Projects{" "}
            </PopButton>
          </a>
        </Flex>

        {/* market size section */}
        <Flex className="marketsize__wrap" vertical align="flex-start">
          <h2 className="marketsize__header">The PopRev Solution</h2>
          <p className="marketsize__subheader">
            <span className="solution__missiontext">Mission:</span> To
            revolutionize the music industry by creating an interactive and
            profitable ecosystem for artists and fans
          </p>
          <Flex className="marketsize__valuations__wrap ">
            <Flex
              className="marketsize__valuations__main"
              vertical
              align="flex-start"
            >
              <p className="marketsize__amount">Fan Investment:</p>

              <p className="marketsize__valuation__body">
                Fans can invest in music projects, providing upfront funding for
                artists. This innovative model enables fans to contribute
                financially to the creation of new music, fostering a deeper
                connection between artists and their audience.
              </p>
            </Flex>
            <Flex
              className="marketsize__valuations__main"
              vertical
              align="flex-start"
            >
              <p className="marketsize__amount">Revenue Sharing:</p>

              <p className="marketsize__valuation__body">
                Fans earn a share of future streaming revenue based on their
                investment. This revenue-sharing mechanism ensures that fans are
                not only supporting their favourite artists financially but also
                have the potential to earn returns based on the success of the
                music projects they&#39;ve invested in.
              </p>
            </Flex>

            <Flex
              className="marketsize__valuations__main"
              vertical
              align="flex-start"
            >
              <p className="marketsize__amount">Artist Empowerment:</p>

              <p className="marketsize__valuation__body">
                Artists gain direct access to funding and a loyal fanbase
                committed to their success. This direct artist-to-fan
                relationship fosters greater creative freedom and financial
                independence for artists.
              </p>
            </Flex>
          </Flex>
        </Flex>

        {/* draftpicks section */}
        <Flex className="draftpick__section__wrap" vertical align="flex-start">
          <h2 className="yellow__header__subtext">Draft Picks</h2>
          <Flex
            className="draftpick__header__section"
            align="flex-end"
            justify="space-between"
          >
            <Flex
              className="draftpick__header__left"
              vertical
              align="flex-start"
            >
              {" "}
              <h2 className="draftpick__headertext">Music – Artist Coin</h2>
              <p className="draftpick__subheader">
                Browse, contribute to a wide range of carefully selected music
                projects.
              </p>
            </Flex>
            <a
              href="https://payd.poprev.dev/signup"
              rel="noreferrer"
              target="_blank"
            >
              <PopButton width="15rem">
                Sign in to see more Draft Picks
              </PopButton>
            </a>
          </Flex>

          <Flex className="draftpicks__cards__wrap">
            <Flex gap="1rem">
              {draftPicksData?.data?.map(
                (item: Record<string, any>, idx: number) =>
                  item.status.toLowerCase() === "deployed" && (
                    <ArtistCard artistData={item} key={idx} />
                  )
              )}
            </Flex>
          </Flex>
        </Flex>

        <Flex className="why__poprev__section" gap="2rem" vertical id="about">
          <Flex className="why__poprev__tabssection" justify="space-between">
            <Flex className="why__poprev__tabsmain" gap=".7rem">
              {whyPoprevData.map((item: Record<string, any>, idx: number) => (
                <h2
                  className={`tab__item ${
                    activeWhyPoprevcard === idx && "active__whypoprev__tab"
                  }`}
                  onClick={() => scrollIntoView(idx)}
                  key={idx}
                >
                  {item.tabTitle}
                </h2>
              ))}
            </Flex>
            <Flex gap=".5rem">
              {activeWhyPoprevcard > 0 && (
                <div onClick={() => scrollIntoView(activeWhyPoprevcard - 1)}>
                  <ArrowLeftSquareOutline width="40" />
                </div>
              )}
              {activeWhyPoprevcard < 4 && (
                <div onClick={() => scrollIntoView(activeWhyPoprevcard + 1)}>
                  <ArrowRightSquareOutline width="40" />
                </div>
              )}
            </Flex>
          </Flex>
          <Flex className="whypopre__card__wrapmain" gap="1rem">
            <Flex
              className="whypopre__card whypopre__card__mainhead"
              vertical
              align="flex-start"
            >
              <h2 className="yellow__header__subtext whypoprev__yellow__subtext">
                Poprev
              </h2>
              <h2 className="whyporev__mainheader">
                Why <br />
                POPREV?
              </h2>
              <p className="whypoprev__cardbody">
                PopRev stands out from traditional platforms with its unique
                focus on music participation. Here's why PopRev is different:
              </p>
              <a
                href="https://prospects.poprev.dev/"
                rel="noreferrer"
                target="_blank"
              >
                <PopButton>Submit Project</PopButton>
              </a>
            </Flex>
            <Flex
              className="whypoprev__subcards__wrap"
              ref={landingDraftPickCardRef}
            >
              <Flex gap="1rem">
                {whyPoprevData.map((item: Record<string, any>, idx: number) => (
                  <Flex
                    className={`whypopre__card  ${
                      activeWhyPoprevcard === idx && "active__whypoprev__card"
                    }
                    `}
                    vertical
                    key={idx}
                    align="flex-start"
                    id={"whyporev__card" + idx}
                  >
                    {item.icon}
                    <h2 className="whyporev__cardheader">{item.title}</h2>
                    <p className="whypoprev__cardbody">{item.description}</p>
                  </Flex>
                ))}
              </Flex>
            </Flex>
          </Flex>
        </Flex>

        {/* security section */}
        <Flex className="security__section" justify="space-between" gap="1rem">
          <Flex className="security__leftsection" vertical align="flex-start">
            <h2 className="seurity__header">Security</h2>
            <h2 className="security__sbheader">
              We have the right safeguards to protect your contributions and
              funds.
            </h2>
            <Flex className="security__image security__image__mobile">
              <img src={SecurityImage} alt="" />
            </Flex>
            <p className="security__bodytext" id="faqs">
              Here at PopRev we take security seriously. We use
              industry-standard encryption and security protocols to protect
              your contributions, funds, and personal information.
            </p>
          </Flex>
          <Flex className="security__image security__image__web">
            <img src={SecurityImage} alt="" />
          </Flex>
          <Flex className="security__image__lines">
            <img src={SecurityLines} alt="" />
          </Flex>
        </Flex>

        {/* faqs */}
        <Flex className="faqs__wrap" vertical gap="1rem">
          <h2 className="faqs__header">FAQS </h2>
          {faqData?.data
            .slice(0, 4)
            .map((item: Record<string, any>, idx: number) => (
              <Flex
                className="faq__item_wrap"
                id={activeFaq === idx ? "active__faq" : ""}
                vertical
              >
                <Flex className="faq___item__header__wrap">
                  <p className="faq__item__headertext">{item.question}</p>{" "}
                  {activeFaq === idx ? (
                    <HiMinus onClick={() => setActiveFaq(-1)} />
                  ) : (
                    <HiPlus onClick={() => setActiveFaq(idx)} />
                  )}
                </Flex>
                <p className="faq__body">
                  {convert(convertTermsToHTML(item?.stringifiedHtmlContent))}
                </p>
              </Flex>
            ))}

          <Flex className="didntfinqfad__wrap" gap="1rem">
            <p className="didntfind__text">Didn’t find you answer above? </p>{" "}
            <a
              href="https://payd.poprev.dev/faq"
              rel="noreferrer"
              target="_blank"
            >
              <PopButton width="10rem">More FAQ’s here</PopButton>
            </a>
          </Flex>
        </Flex>

        {/* disclaimer */}
        {/* <Flex className="disclaimer__wrap" vertical align="flex-start">
          <h2 className="yellow__header__subtext">LEGAL DISCLAIMER</h2>
          <p className="disclamer__body">
            PopRev operates as a platform that facilitates participation in
            music projects and related activities. By accessing or using our
            platform, you acknowledge and agree to the following:
          </p>
          <PopButton  >
            See more
          </PopButton>
        </Flex> */}
      </HomePageWrap>
    </LandingLayout>
  );
};

export default HomePage;
