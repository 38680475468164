// general layout for investor General page

import React, { useEffect } from "react";
import { LandingLayoutWrap } from "./landingLayout.styles";
import Header from "./Header/header";
import Footer from "./Footer/footer";

interface IProps {
  children: any;
}

const LandingLayout: React.FC<IProps> = ({ children }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <LandingLayoutWrap>
      <Header />
      <main className="dashboard__layout__main">{children}</main>
      <Footer />
    </LandingLayoutWrap>
  );
};

export default LandingLayout;
