import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import React from "react";
import HomePage from "../pages/HomePage/homepage";
import TermAndConditions from "../pages/TermsAndConditions/termsAndConditions";
import PrivacyPolicy from "../pages/PrivacyPolicy/privacyPolicy";
import PurchaseTerms from "../pages/PurchaseTerms/purchaseTerms";
import CookiePolicy from "../pages/CookiePolicy/cookiePolicy";

export const MainRoutes = () => {
  return (
    <Router>
      <React.Fragment>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/t&c" element={<TermAndConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/purchase-terms" element={<PurchaseTerms />} />
          <Route path="/cookie-policy" element={<CookiePolicy />} />
        </Routes>
      </React.Fragment>
    </Router>
  );
};
