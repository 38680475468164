import { Flex } from "antd";
import styled from "styled-components";

export const FooterWrap = styled(Flex)`
  width: 100%;
  background-color: red;

  .ant-form,
  .form_wrapsub {
    width: 100%;
  }

  .header__text {
    font-weight: 500;
    font-size: 0.9rem;
  }
  .body__text {
    opacity: 0.6;
    font-weight: 500;
    a {
      margin: 0;
    }
    a:hover {
      color: var(--yellowPrimary);
    }
  }
  .footer__topsection {
    background-color: var(--blackSecondaryTwo);
    width: 100%;
    padding: 2rem 8rem;

    .footernav__items__wrap {
      width: 100%;
      width: 28rem;
      align-items: flex-start;

      .footernav__item {
        align-items: flex-start;
      }
    }
    .footer__logo__wrap {
      width: 8rem;
      cursor: pointer;
      img {
        object-fit: contain;
      }
    }

    .constactus__wrap {
      width: 22rem;
    }
  }
  .footer__bottomsection {
    background-color: var(--blackSecondary);
    padding: 2rem 3rem;
    .socials__wrap {
      svg {
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        :hover {
          color: var(--yellowPrimary);
        }
      }
    }

    .extralinks__wrap {
      gap: 2.5rem;
      #active__route {
        color: var(--yellowPrimary);
      }
    }
  }

  @media screen and (max-width: 1050px) {
    .footer__topsection {
      padding: 2rem 15px;

      .footer__topsection__upwrap {
        flex-direction: column;
        align-items: center;
      }
      .footer__logo__wrap {
        margin: 1rem 0;
      }
      .footernav__items__wrap,
      .constactus__wrap {
        width: 100%;
        button {
          width: 100%;
        }
      }
    }
    .footer__bottomsection {
      padding: 2rem 15px;
    }
  }

  @media screen and (max-width: 800px) {
    .header__text {
      font-size: 1rem;
    }
    .body__text {
      font-size: 0.9rem;
      line-height: 1.5rem;
      a {
        font-size: 0.9rem;
      }
    }
    .footer__bottomsection {
      flex-direction: column;
      .socials__wrap {
        width: max-content;
      }

      .extralinks__wrap {
        flex-wrap: wrap;
        margin-top: 1rem;
        gap: 0.5rem;
        .body__text {
          width: 45%;
        }
      }
    }
  }
`;
