import styled from "styled-components";
import { Button } from "antd";

type Custom = {
  width?: string;
  height?: string;
  padding?: string;
  margin?: string;
  backgroundColor?: string;
  border?: string;
  borderColor?: string;
  opacity?: string;
  radius?: string;
  boxShadow?: string;
  fontSize?: string;
  color?: string;
  hoverColor?: string;
  fontWeight?: string;
  hoverBg?: string;
  outlined?: boolean;
  maxWidth?: string;
  svgOpacity?: string;
};

export const PopButton = styled(Button)<Custom>`
  width: ${({ width }) => width || "8rem"};
  margin: ${({ margin }) => margin};
  height: ${({ height }) => height || "2.3rem"};
  min-height: ${({ height }) => height || "45px"};
  padding: ${({ padding }) => padding || "0"};
  border: ${({ border }) => border || ".08rem solid var(--yellowPrimary)"};
  border-color: ${({ backgroundColor, borderColor }) =>
    borderColor ? borderColor : backgroundColor || "var(--yellowPrimary)"};
  background: ${({ backgroundColor, outlined }) =>
    outlined ? "transparent" : backgroundColor || "var(--yellowPrimary)"};
  border-radius: ${({ radius }) => radius || ".2rem"};
  box-shadow: ${({ boxShadow }) => boxShadow || "none"};
  opacity: ${({ opacity }) => opacity || "1"};
  outline: none;
  cursor: pointer;
  transition: all 0.3s ease;

  span {
    color: ${({ color, outlined }) =>
      outlined ? "var(--yellowPrimary)" : color || "var(--bleckPrimary)"};
    font-size: ${({ fontSize }) => fontSize || ".85rem"};
    font-weight: ${({ fontWeight }) => fontWeight || "600"};
  }

  &:hover,
  &:focus,
  &:active {
    background-color: ${({ outlined }) =>
      outlined ? "var(--yellowPrimary)" : "transaprent"};
    border-color: ${({ backgroundColor }) =>
      backgroundColor || "var(--yellowPrimary)"};
    outline: none;
    transform: scale(1.03);

    span,
    a {
      color: var(--blackPrimary);
      background-color: transparent;
    }
  }
`;
