import { ArtistCardWrap } from "./draftpickCard.styles";
import { useState } from "react";
import { useEffect } from "react";
import { Flex } from "antd";
import { validateImages } from "../../../utils/function-helpers/validateFileTypes";
import { PopButton } from "../Button";
import moment from "moment";

interface IProps {
  cardData?: any;
  artistData?: Record<string, any>;
}

const ArtistCard: React.FC<IProps> = ({ artistData }) => {
  const [projectImages, setProjectImages] = useState([""]);

  useEffect(() => {
    const getImages = artistData?.projectMedia?.filter((media: string) =>
      validateImages(media)
    );
    setProjectImages([...projectImages, ...getImages]);
  }, [artistData?.projectMedia, setProjectImages]);

  return (
    <ArtistCardWrap>
      <Flex className="card__image__wrap">
        <Flex className="card__image__wrap__inner">
          <Flex className="card__image">
            <img src={projectImages[1]} alt="" />
          </Flex>
        </Flex>
      </Flex>
      <Flex
        className="other__details__wrap"
        align="flex-start"
        justify="space-between"
        vertical
      >
        <Flex className="texts__wrap__main">
          <Flex vertical align="flex-start" className="texts__wrap">
            <h2 className="draftpickcards__texts">
              Draft Pick: &nbsp;{" "}
              {artistData?.artist?.moniker || artistData?.title}
            </h2>

            <h2 className="draftpickcards__texts">
              Artist Coin: &nbsp; {artistData?.coin?.syntax}
            </h2>
          </Flex>
          <Flex vertical align="flex-start" className="texts__wrap">
            <h2 className="draftpickcards__texts">
              Release: &nbsp; {artistData?.type || artistData?.projectType}
            </h2>

            <h2 className="draftpickcards__texts">
              Drop Date: &nbsp;{" "}
              {moment(artistData?.launchDate).format("DD-MM-YYYY") ||
                moment(artistData?.launch).format("DD-MM-YYYY")}
            </h2>
          </Flex>
        </Flex>

        <Flex className="buttons__wrap" justify={"space-between"} gap="1rem">
          <a
            href={`https://payd.poprev.dev/payd/project/${artistData?.id}?coinId=${artistData?.coin?.id}&artistId=${artistData?.artist?.id}`}
            rel="noreferrer"
            target="_blank"
          >
            <PopButton
              width="100%"
              height="2.2rem"
              borderColor="#707070"
              backgroundColor="transparent"
              color="var(--whitePrimary)"
              radius=".15rem"
              fontSize=".7rem"
            >
              The Project
            </PopButton>
          </a>
          {artistData?.status?.toLowerCase() === "deployed" && (
            <a
              href={`https://payd.poprev.dev/payd/buy-coins`}
              rel="noreferrer"
              target="_blank"
            >
              <PopButton
                width="100%"
                height="2.2rem"
                radius=".15rem"
                fontSize=".7rem"
              >
                Invest Now
              </PopButton>
            </a>
          )}
        </Flex>
      </Flex>
    </ArtistCardWrap>
  );
};

export default ArtistCard;
