import React, { useEffect } from "react";
import { ToastMessageWrap } from "./toastMessages.styles";
import ToastImage from "../../../assets/images/toast-message.svg";
import { Flex } from "antd";

interface IProps {
  message?: Record<string, any>;
  type?: string;
  setMessage?: any;
}

const ToastMessage: React.FC<IProps> = ({ message, setMessage }) => {
  useEffect(() => {
    if (setMessage) {
      setTimeout(() => {
        setMessage({});
      }, 10000);
    }
  }, [setMessage]);

  return (
    <ToastMessageWrap
      justify="flex-start"
      type={message?.type}
      setMessage={setMessage}
      align="center"
      gap="1rem"
    >
      <Flex className="icon__wrap">
        <img src={ToastImage} alt="" />
      </Flex>
      <Flex className="text__wrap" align="center">
        <p>{message?.text}</p>
      </Flex>
    </ToastMessageWrap>
  );
};

export default ToastMessage;
