import React, { useState } from "react";
import { FormItem, TextFieldParentWrap } from "./textfield.styles";
import { BsExclamationCircleFill } from "react-icons/bs";

interface IProps {
  props?: any;
  placeholder?: string;
  validator: any;
  type?: string;
  name?: string;
  radius?: string;
  handleChange?: any;
  handleCheckChange?: any;
  isChecked?: boolean;
  defaultValue?: string;
  disabled?: boolean;
  margin?: string;
  width?: string;
  label?: string;
}

type StatusProps = {
  success: string;
  error: string;
};

export const TextField: React.FC<IProps> = (
  {
    placeholder,
    validator,
    type,
    name,
    radius,
    defaultValue,
    disabled,
    margin,
    width,
  },
  props
) => {
  const [status, setStatus] = useState<StatusProps>();

  return (
    <TextFieldParentWrap
      status={status}
      margin={margin}
      width={width}
      radius={radius}
    >
      <FormItem
        {...props}
        name={name}
        status
        radius={radius}
        rules={[
          {
            validator: (rules, value) => validator(rules, value, setStatus),
          },
        ]}
        initialValue={defaultValue}
        backgroundColor={disabled ? "#f2f2f2" : "#fff"}
      >
        <input
          onChange={(e) => {
            validator(e, e?.target?.value, setStatus);
          }}
          onBlur={(e) => {
            validator(e, e?.target?.value, setStatus);
          }}
          placeholder={placeholder}
          type={type || "text"}
          defaultValue={defaultValue}
          disabled={disabled}
        />
      </FormItem>

      {status?.error && (
        <div className="error__icon">
          <BsExclamationCircleFill />
        </div>
      )}

      {status?.error && <p className="error__text">{status.error}</p>}
    </TextFieldParentWrap>
  );
};
