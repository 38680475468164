import { Flex } from "antd";
import styled from "styled-components";

export const FeaturedProjectCardWrap = styled(Flex)`
  background-color: var(--blackSecondaryTwo);
  padding: 1.2rem 1rem;
  border-radius: 0.8rem;
  position: relative;
  width: 18.5rem;
  gap: 0.5rem;
  align-items: flex-end;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.1);
  }

  .card__logowrap {
    width: 3rem;
    height: 2.8rem;
    background-color: var(--blackSecondary);
    border-radius: 0.3rem;
  }
  .card__header__wrap {
    width: calc(100% - 6.8rem);
    .card__body_text {
      margin: 0;
      font-weight: 500;
      opacity: 0.2;
      font-size: 0.78rem;
    }
  }
  .card__h3__text {
    letter-spacing: 0.08rem;
    font-size: 0.9rem;
    white-space: nowrap;
    margin: 0;
  }
  .card__percentage__text {
    color: var(--yellowPrimary);
  }
  .heart__wrap {
    border-radius: 100%;
    width: 2rem;
    height: 2rem;
    background-color: var(--blackSecondaryTwo);
    position: absolute;
    bottom: -0.8rem;
    left: -1rem;
  }
`;
