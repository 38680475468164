import { Flex } from "antd";
import styled from "styled-components";

export const LandingLayoutWrap = styled(Flex)`
  background-color: var(--blackPrimary);
  flex-direction: column;
  position: relative;
  overflow-x: hidden;

  @media screen and (max-width: 1024px) {
    .landing__layout__main {
    }
  }
`;
