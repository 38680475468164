export const faqs = [
  {
    question: "What is PopRev?",
    stringifiedHtmlContent: `● PopRev is a groundbreaking platform that allows music enthusiasts to invest
in their favourite artist's projects by purchasing digital coins. These coins
represent a share of future streaming revenue generated by the artist's music.
PopRev gives musicians access to funds to push their projects whilst offering
fans/investors the ability to buy shares in the catalogue.`,
  },
  {
    question: "How does PopRev work?",
    stringifiedHtmlContent: `● PopRev works by offering digital coins tied to specific music projects.
Investors can purchase these coins, providing financial support to the artist.
As the artist's music generates streaming revenue, investors earn returns
based on their coin ownership.`,
  },
  {
    question: "Who can invest on PopRev?",
    stringifiedHtmlContent: `● Anyone who meets the platform's eligibility criteria can invest on PopRev,
including individual investors, music fans, and institutional investors.`,
  },

  {
    question: "What is Revver ID",
    stringifiedHtmlContent: `● As a Poprev account holder your Revver ID is the username you created at the point
of creating your Payd Poprev account.`,
  },
];
export const featuredList = [
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
];
