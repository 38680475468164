import axios from "axios";

export const instance = axios.create({
  baseURL: "https://auth-api.poprev.dev",
  headers: {
    "Content-Type": "application/json",
  },
});

export const adminInstance = axios.create({
  baseURL: "https://admin-api.poprev.dev",
  headers: {
    "Content-Type": "application/json",
  },
});
