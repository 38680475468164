import { adminInstance, instance } from "./axios";

export const getDraftPicks = async () => {
  const { data } = await instance.get("/user/artist-projects");
  return data;
};

export const getFAQs = async () => {
  const { data } = await instance.get(`/user/faq/many?category=GENERAL`);
  return data;
};

export const getFeaturedDraftPicks = async () => {
  const { data } = await instance.get("/user/artist-projects/featured");
  return data;
};
export const joinNewsletter = async (payload: Record<string, any>) => {
  const { data } = await adminInstance.post("/mail-list/newsletter", payload);
  return data;
};
