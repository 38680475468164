import React, { useEffect, useState } from "react";
import { Flex } from "antd";
import { FeaturedProjectCardWrap } from "./featuredCard.styles";
import { validateImages } from "../../../utils/function-helpers/validateFileTypes";

interface IProps {
  cardData?: any;
}

const FeaturedProjectCard: React.FC<IProps> = ({ cardData }) => {
  const [projectImages, setProjectImages] = useState([""]);

  useEffect(() => {
    const getImage = cardData.projectMedia.filter(
      (media: Record<string, any>) => validateImages(media)
    );
    setProjectImages([getImage]);
  }, []);

  return (
    <a
      href={`https://payd.poprev.dev/payd/project/${cardData?.id}?coinId=${cardData?.coin?.id}&artistId=${cardData?.artist?.id}`}
      rel="noreferrer"
      target="_blank"
    >
      <FeaturedProjectCardWrap>
        <div className="card__logowrap">
          <img src={projectImages[0]} alt="" />
        </div>
        <Flex
          className="card__header__wrap"
          vertical
          align="flex-start"
          gap=".5rem"
        >
          <h3 className="card__h3__text">{cardData?.title}</h3>
          <span className="card__body_text">
            {cardData?.status === "DEPLOYED"
              ? "Live Projects"
              : "Launching Soon"}
          </span>
        </Flex>
        <h3 className="card__h3__text card__percentage__text">UP 0%</h3>
      </FeaturedProjectCardWrap>
    </a>
  );
};

export default FeaturedProjectCard;
