/* 
Filetype can only have: 
- image
*/
export const validateImages = (filename) => {
  const imageTypes = ["png", "svg", "jpeg", "jpg", "webp"];
  let returnValue;
  imageTypes.map((item) => {
    if (filename.toLowerCase().includes(item)) {
      returnValue = true;
    }
  });
  return returnValue;
};
