import { Flex } from "antd";
import styled from "styled-components";

export const TermsAndPolicyLayoutWrapper = styled(Flex)`
  background-color: var(--blackSecondary);
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  padding: 5rem;

  .terms__header {
    font-size: 2.5rem;
    color: var(--yellowPrimary);
    text-align: center;
    width: 100%;
    margin-bottom: 1.5rem;
    padding-top: 4rem;
  }

  .terms__subheader {
    font-size: 1rem;
    text-align: center;
    width: 100%;
  }
  .terms__subheader__alignedleft {
    text-align: start;
  }

  .terms__body__title {
    font-size: 1.2rem;
    margin-top: 2rem;
    color: var(--yellowPrimary);
    text-align: left;
  }
  .terms__body__text {
    font-size: 0.85rem;
    text-align: start;
    line-height: 1.5rem;
  }

  a {
    color: var(--yellowPrimary);
    text-decoration: underline;
  }

  @media (max-width: 1000px) {
    padding: 5rem 15px;
  }
`;
