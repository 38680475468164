import { Header } from "antd/es/layout/layout";
import styled from "styled-components";

type Custom = { showMobileMenu?: boolean; navBackground?: boolean };

export const HeaderWrap = styled(Header)<Custom>`
  width: 100%;
  height: 6rem;
  padding: 1rem 8rem 0 8rem;
  background-color: ${({ navBackground }) =>
    navBackground ? "var(--blackSecondary)" : "transparent"};
  transition: all 0.3s ease;
  position: fixed;
  z-index: 9;
  top: 0;

  .header__wrap__main {
    .header__logo__wrap {
      width: 5rem;
      cursor: pointer;
      img {
        object-fit: contain;
      }
    }
    .register__button {
      cursor: pointer;
    }

    .hearder__right__section {
      width: 30rem;
      justify-content: space-between;
      gap: 1rem;
      align-items: center;

      .menu__item {
        white-space: nowrap;
        color: var(--whitePrimary);
        transition: all 0.3s ease;
        position: relative;
        :hover {
          color: var(--yellowPrimary);
        }
        ::before {
          position: absolute;
          bottom: -0.3rem;
          border-radius: 0.3rem;
          left: 0;
          content: "";
          height: 0.1rem;
          width: 0;
          transition: all 0.3s ease;
          background-color: var(--yellowPrimary);
        }
      }
      #active__menu__item {
        ::before {
          width: 100%;
        }
      }
    }
  }
  @media screen and (min-width: 800px) {
    .mobile__menuicon {
      display: none;
    }
  }

  @media screen and (max-width: 1050px) {
    padding: 1rem 1rem 0 1rem;

    .header__wrap__main {
      .hearder__right__section {
        gap: 0.6rem;
        width: 25rem;
      }
    }
  }

  @media screen and (max-width: 800px) {
    padding: 0 15px 0 15px;

    .header__wrap__main {
      align-items: flex-end;
      height: 100%;

      .mobile__menuicon {
        cursor: pointer;
        height: 3.5rem;
        :hover {
          color: var(--yellowPrimary);
        }
      }

      .header__logo__wrap {
        width: 4rem;
      }

      .hearder__right__section {
        position: fixed;
        z-index: 9;
        height: calc(100% - 6rem);
        overflow: hidden;
        width: ${({ showMobileMenu }) => (showMobileMenu ? "100vw" : "0")};
        top: 5.95rem;
        right: ${({ showMobileMenu }) => (showMobileMenu ? "0" : "-4rem")};
        background-color: var(--blackSecondary);
        flex-direction: column;
        gap: 5rem;
        padding: 4rem 18px 2rem 18px;
        transition: all 0.3s ease;
        align-items: flex-end;
        justify-content: flex-start;
        .menu__item {
          a {
            font-size: 1.3rem;
          }
        }
        .register__button {
          position: absolute;
          bottom: 2rem;
          width: calc(100% - 36px);
          button {
            width: 100%;
            span {
              font-size: 1rem;
            }
          }
        }
      }
    }
  }
`;
